<template>
  <div
    class="attrs-fold"
  >
    <FoldEntry
      :compShowConfig="compShowConfig"
      :language="language"
      :saleAttrsArr="saleAttrsArr"
      :sizeStockTips="sizeStockTips"
      :soldOutTips="soldOutTips"
      :colorsFoldInfo="colorsFoldInfo"
      :sizeInfoIndex="sizeInfoIndex"
      :calcSize="calcSize"
      :showHint="showHint"
      :closeHint="closeHint"
      :openQuick="openQuick"
    />
    <ClientOnly v-if="allDataReady">
      <template v-if="compShowConfig.showAttrInfoComp">
        <template
          v-for="(item, index) in attrPicInfos"
        >
          <ColorV2Desc
            v-if="item.isMainAttr"
            :key="goodsId || index"
            :goodsDesc="goodsDesc"
            :language="language"
            :goodsMainAttPicInfo="goodsMainAttPicInfo"
            :maxRetry="30"
            from="page"
            :goodsId="goodsId"
            :style="{ marginBottom: '0.11rem' }"
          />
          <AttrPicInfo
            v-else
            :key="item.attr_value_id || index"
            :show="!!item" 
            :picInfo="item"
            :style="{ marginBottom: '0.11rem' }"
          />
        </template>
        <SizeSlideTips
          v-if="showDesc && !hideOneSizeModule"
          :slide-tips-params="{
            showDesc,
            sizeInfoStr,
            sizeInfoTitle,
            showSizeAttributeEntrance: sizeConfig.showSizeAttributeEntrance,
          }"
          @openSizeGuide="openSizeGuide"
        />
      </template>
      <SizeTipsContent 
        v-if="compShowConfig.showTipsComp && showTipsContent"
        :tips-combo="tipsCombo"
        :true-to-size="trueToSize"
        :is-show-size-tips="isShowSizeTips"
        :isShowSizeTipsText="isShowSizeTipsText"
        @openSizeGuideFromSizeTips="openSizeGuideFromSizeTips"
        @openReviews="openReviews"
      />
      <template v-if="compShowConfig.showFeedBackComp && hasTargetCatId && feedBackBasicSizes.length">
        <SizeFeedBackEnter @onSizeFeedbackBtnClick="onSizeFeedbackBtnClick" />
        <SizeFeedBack
          ref="SIZE_FEED_BACK"
          v-bind="sizeFeedBackBind"
          :feedBackBasicSizes="feedBackBasicSizes"
          @localClick="clickLocalTextSelectHandle"
        />
      </template>
      <Mall 
        v-if="compShowConfig.showMallEntry"
        v-bind="mallBind"
        :choose-check="chooseCheck"
        @mallClick="mallClick"
      />
      <QuickShip
        v-show="compShowConfig.showQuickShipEntry"
        v-bind="quickShipBind"
        @updateQuickShip="handleUpdateQuickShip"
      />
    </ClientOnly>
  </div>
</template>

<script>
export default {
  name: 'SaleAttrsFoldComp'
}
</script>

<script setup>
import { onMounted, watch, provide, ref, nextTick, defineAsyncComponent } from 'vue'
import { useMapGetters, useMapState, useMapMutations, useMapActions } from 'public/src/pages/goods_detail/utils/useVuex.js'
import useSizeInfoIndex from './compositions/useSizeInfoIndex.js'
import useSizeAttrInfo from './compositions/useSizeAttrInfo.js'
import useSizeTip from './compositions/useSizeTip.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { queryString } from 'public/src/pages/common/utils/index.js'

// 同步组件
import ClientOnly from 'vue-client-only'
import FoldEntry from './components/FoldEntry.vue'

import { COMMON_STATE_KEYS, FOLD_GETTER_KEYS, COMMON_GETTER_KEYS, COMMON_MUTATION_KEYS } from './constants.js'

// 异步组件
const ColorV2Desc = defineAsyncComponent(() => import('public/src/pages/goods_detail/components/top/innerComponents/ColorBox/ColorV2Desc.vue'))
const AttrPicInfo = defineAsyncComponent(() => import('public/src/pages/components/sizes/components/AttrPicInfo.vue'))
const SizeSlideTips = defineAsyncComponent(() => import('public/src/pages/components/sizes/components/UI/SizeSlideTips.vue'))
const SizeTipsContent = defineAsyncComponent(() => import('public/src/pages/components/sizes/components/UI/SizeTipsContent.vue'))
const SizeFeedBackEnter = defineAsyncComponent(() => import('public/src/pages/components/sizes/components/UI/SizeFeedBackEnter.vue'))
const SizeFeedBack = defineAsyncComponent(() => import('public/src/pages/components/sizes/components/SizeFeedBack.vue'))
const Mall = defineAsyncComponent(() => import('public/src/pages/components/sizes/components/Mall.vue'))
const QuickShip = defineAsyncComponent(() => import('public/src/pages/components/sizes/components/QuickShip.vue'))

const { 
  externalSizeInfoIndex, 
  externalSkuCode,
  skuInfo,
  currentMySize, 
  currentMyBraSize, 
} = useMapState('common', COMMON_STATE_KEYS)

const { 
  saleAttrsArr, 
  colorsFoldInfo,
  onlyOneSize,
  sizeComposeInfo,
  language,
  sizeStockTips,
  soldOutTips,
  sizeConfig,
  sizeConstant,
  currentUnit,
  goodsMainAttPicInfo,
  goodsDesc,
  tipsCombo,
  sizeFeedBackBind,
  localSelectComb,
  quickShipBind,
  mallBind,
  compShowConfig,
  hideOneSizeModule,
} = useMapGetters('newProductDetail/SaleAttrsFold', FOLD_GETTER_KEYS)
const { 
  detail,
  mallCode,
  goodsId,
  parentCats,
  allDataReady,
} = useMapGetters('newProductDetail/common', COMMON_GETTER_KEYS)

const { updateAttrsFoldQuickAddState, updateExternalSkuCode } = useMapMutations('newProductDetail/common', ['updateAttrsFoldQuickAddState', 'updateExternalSkuCode'])
const { handleCompose, mallClick } = useMapActions('newProductDetail/SaleAttrsFold', ['handleCompose', 'mallClick'])
const { initSizeBoxState } = useMapActions('newProductDetail/SizeBox', ['initSizeBoxState'])
const { openViewCommentPop } = useMapActions('newProductDetail/CommentPopup', ['openViewCommentPop'])
const { openSizeGuidePop } = useMapActions('newProductDetail', ['openSizeGuidePop'])

const {
  updateTopContentReady,
  updateSyncAddCartColorSwitch,
  updateCurrentLocalCountry,
  updateQuickShip,
} = useMapMutations('newProductDetail/common', COMMON_MUTATION_KEYS)
const { updateHeelHeightStatus } = useMapMutations('newProductDetail/SizeBox', ['updateHeelHeightStatus'])

const SIZE_FEED_BACK = ref(null)
const showHint = ref(false)

const { hasTargetCatId, feedBackBasicSizes, showHightSize, isShowSizeTips, isShowSizeTipsText, trueToSize, showTipsContent, commonSizeTipsInit } = useSizeTip({}, {
  tipsCombo,
  constant: sizeConstant,
  parentCats,
  language,
  sizeComposeInfo,
})

const { calcSize, sizeValue, bubbleInfo, isShowBaseCode, isInit, sizeInfoIndex, commonSizeInit, sizeEmit, chooseCheck } = useSizeInfoIndex({}, {
  sizeComposeInfo,
  language,
  baseInfo: detail,
  mallCode,
  goodsId,
  config: sizeConfig,
  currentUnit,
  showHightSize,
}, {
  handleCompose,
  mallClick,
})

const { showDesc, sizeInfoStr, sizeInfoTitle, attrPicInfos } = useSizeAttrInfo({}, {
  goodsId,
  bubbleInfo, 
  sizeInfoIndex, 
  isInit, 
  skuInfo, 
  currentUnit,
  isShowBaseCode,
  sizeComposeInfo,
  language,
  sizeConfig,
  goodsMainAttPicInfo,
  goodsDesc,
}, { calcSize })

const defaultClick = (initParams = {}) => {
  if (isInit.value) {
    commonSizeInit(true, initParams)
  }
  sizeEmit({}, false, initParams)
}

const onlyOneSizeCheck = () => {
  if (!onlyOneSize.value) return
  defaultClick()
}

const openSizeGuide = () => {
  openSizeGuidePop({
    fromScene: 'detail_pop'
  })
  daEventCenter.triggerNotice({
    daId: '1-8-1-31',
    extraData: {},
  })
}

const openSizeGuideFromSizeTips = () => {
  if (isShowSizeTipsText.value) {
    openSizeGuide()
    daEventCenter.triggerNotice({
      daId: '1-6-1-114',
      extraData: {
        type: isShowSizeTips.value?.isLargeSizeTips ? 1 : 2,
      },
    })
  }
}

const openReviews = () => {
  location.hash += 'comment-all'
  openViewCommentPop()
  daEventCenter.triggerNotice({
    daId: '1-6-1-114',
    extraData: {
      type: 3,
    },
  })
}

const openQuick = () => {
  updateSyncAddCartColorSwitch(true)
  updateAttrsFoldQuickAddState(true)
}

const onSizeFeedbackBtnClick = () => {
  SIZE_FEED_BACK.value?.onSizeFeedbackBtnClick?.()
}

const sizeLocalChange = (val) => {
  updateCurrentLocalCountry(val)
  daEventCenter.triggerNotice({
    daId: '1-6-1-106',
    extraData: {
      choose_type: val,
    },
  })
  SIZE_FEED_BACK.value?.updateFeedBackList?.()
}

// 唤起本地尺码国家选择浮层
const clickLocalTextSelectHandle = () => {
  import('public/src/pages/components/product/select-local-size/index.js').then(res => {
    const { 
      id,
      value,
      localSize,
      isNewSizeLocal, 
    } = localSelectComb.value || {}
    res?.default?.open?.({
      id, 
      value,
      localSize,
      options: {
        config: {
          appendToBody: true,
          isNewSizeLocal,
        },
        callbacks: {
          change: country => {
            sizeLocalChange(country)
          }
        }
      }
    })
  })
}

const handleUpdateQuickShip = (status) => {
  updateQuickShip(status)
}

// check my size同步选中尺码
const changeRecommendSize = (source, val) => {
  if (!val || !source) return
  sizeValue(val)
  if (typeof window !== 'undefined') {
    window.vBus?.$emit?.('changeRecommendSize', {
      [source]: val,
    })
  }
}

// 切换skc时自动切换有库存的mall
const autoCheckMall = () => {
  if (sizeConfig.value?.openAutoCheckMall && chooseCheck(-1, '', '', mallCode.value)) {
    const hasStockMall = detail?.mall_info?.find((_) => !!chooseCheck(-1, '', '', _?.mall_code))
    const mallCode = hasStockMall?.mall_code || ''
    if (mallCode) {
      mallClick({
        mallCode,
        mallStock: chooseCheck(-1, '', '', mallCode),
      })
    }
  }
}

// 关闭新人提示
const closeHint = () => {
  showHint.value = false
}

// 获取新人提示信息
const getNewHint = () => {
  const used = localStorage.getItem('detail_attrs_fold_used')
  if (!used) {
    showHint.value = true
    localStorage.setItem('detail_attrs_fold_used', '1')
    const timer = setTimeout(() => {
      closeHint()
      clearTimeout(timer)
    }, 5000)
  }
}

// 取出本地存储的国家信息
const updateLocalCountry = () => {
  nextTick(() => {
    const country = window.localStorage.getItem('last_select_country')
    if (!country) return
    // 像是多余的
    // window.localStorage.setItem('cache_last_select_country', country || '')
    updateCurrentLocalCountry(country)
  })
}

// 初始化
const initSizeBox = () => {
  initSizeBoxState()
  updateLocalCountry()
}

// ---provide---

provide('language', language.value)
provide('constant', sizeConstant.value)

// ---provide End---

// ---watch---
// TODO: showHeelHeight（像是没必要了）

watch(goodsId, (newVal, oldVal) => {
  // 跟高状态
  updateHeelHeightStatus(false)
  isInit.value = false
  onlyOneSizeCheck()
  commonSizeTipsInit()
  nextTick(() => {
    if (currentMySize) {
      changeRecommendSize('currentMySize', currentMySize)
    }
    if (currentMyBraSize) {
      changeRecommendSize('currentMyBraSize', currentMyBraSize)
    }

    // TODO: 曝光信息（应该不需要了）
    // exposeSizeHandle()

    if (oldVal && newVal !== oldVal) {
      autoCheckMall()
    }
  })
})

watch(currentMySize, (val) => changeRecommendSize('currentMySize', val))

watch(currentMyBraSize, (val) => changeRecommendSize('currentMyBraSize', val))

watch(externalSizeInfoIndex, (val) => {
  if (val && Object.keys(val).length > 0 && JSON.stringify(val || {}) != JSON.stringify(sizeInfoIndex.value)) {
    defaultClick({
      needSetSizeIndex: val,
    })
  }
})

watch(externalSkuCode, (val) => {
  if (val && val != skuInfo.value?.sku_code) {
    defaultClick({
      needSetSkuInfo: val,
    })
  }
})

watch(() => sizeComposeInfo.value.skuMapCompose, () => {
  sizeEmit()
})

// ---mounted---

onMounted(() => {
  // ---SizeBox---
  initSizeBox()
  const timer = setTimeout(() => {
    clearTimeout(timer)
    updateTopContentReady(true)
    const { skucode = '' } = queryString.parse(location.search)
    updateExternalSkuCode(skucode) 
  }, 100)
  // ---SizeBox End---

  // ---sizes---
  onlyOneSizeCheck()
  // exposeSizeHandle()(像是没用了)
  // prefetchResources() (预加载资源) => 监听allDataReady
  const unWatch = watch(allDataReady, (val) => {
    if (!val) return
    unWatch()
    nextTick(() => {
      commonSizeTipsInit()
    })
    // 延迟防止页面抖动导致气泡位置不准确
    const timer = setTimeout(() => {
      clearTimeout(timer)
      getNewHint()
    }, 1000)
  })
  // ---sizes End---
})
</script>
