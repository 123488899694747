<script lang="jsx">

const labelConfig = {
  UPPER_LEFT_LABEL: {
    cls: 'locate-label__upper-left',
  },
  UPPER_RIGHT_LABEL: {
    cls: 'locate-label__upper-right',
  },
  LOWER_LEFT_LABEL: {
    cls: 'locate-label__lower-left',
  },
  LOWER_RIGHT_LABEL: {
    cls: 'locate-label__lower-right',
  },
}

const GB_cssRight = !!gbCommonInfo?.GB_cssRight
// 系列角标
const seriesLabels = ['series_image', 'series_jointly_image']
// 品牌角标
const brandLabels = ['brand_image', 'brand_jointly_image']

export default {
  name: 'LocateLabels',
  functional: true,
  props: {
    labelsInfo: {
      type: Object,
      default: () => ({})
    },
    lazyImg: {
      type: String,
      default: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
    },
    moveUpStyle: {
      type: Boolean,
      default: false
    },
    // 指定显示的角标类型
    labelsTypes: {
      type: Array,
      default () {
        return []
      }
    }
  },
  render(h, { props }) {
    const result = []
    const { labelsInfo, lazyImg, moveUpStyle, labelsTypes } = props

    Object.keys(labelsInfo).forEach(key => {
      if (!Object.values(labelsInfo[key] || {}).length) {
        return
      }
      const materialValueKey = labelsInfo[key].materialValueKey
      const isImgType = labelsInfo[key].subscriptType === 'image' || [...seriesLabels, ...brandLabels].includes(materialValueKey)
      const fontColor = labelsInfo[key].fontColor || ''
      const backgroundColor = labelsInfo[key].backgroundColor || ''

      // 通用角标取 icon / 品牌角标取 corner_img_left
      let icon = labelsInfo[key].icon || labelsInfo[key].corner_img_left

      // 系列角标区分ar站点
      if (seriesLabels.includes(materialValueKey)) {
        icon = GB_cssRight ? labelsInfo[key].corner_img_right : labelsInfo[key].corner_img_left
      }

      if (labelsTypes?.length === 0 || (labelsTypes?.length > 0 && labelsTypes.includes(materialValueKey))) {
        result.push({
          key,
          isImgType,
          fontColor,
          backgroundColor,
          cls: {
            [labelConfig[key].cls]: true,
            ['locate-label__type-img']: isImgType,
            lazyload: isImgType,
            ['locate-label__type-text']: !isImgType,
            ['locate-label__move-up']: moveUpStyle && (key === 'LOWER_LEFT_LABEL' || key === 'LOWER_RIGHT_LABEL'),
          },
          icon,
          labelText: labelsInfo[key].labelLang || labelsInfo[key].labelLangEn,
        })
      }
    })

    const getTextStyle = (item) => {
      return {
        backgroundColor: item.backgroundColor,
        color: item.fontColor,
      }
    }

    return (
      <div>
        {
          result.map(item => (
            item.isImgType ? (
              <img
                key={item.key + 'img'}
                src={lazyImg}
                data-src={item.icon}
                data-design-width={'750'}
                class={item.cls}
              />
            ) : (
              <div
                key={item.key + 'text'}
                class={item.cls}
                style={getTextStyle(item)}
              >
                {item.labelText}
              </div>
            )
          ))
        }
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
@space-text : .32rem;
@space-img  : 0px;

.locate-label__type-text {
  display: inline-block;
  line-height: 17px;
  font-size: 14px;
  text-align: center;
  padding: .0534rem .16rem;
  background-color: #333;
  color: #fff;
  min-width: .89rem;
}
.locate-label__upper-left {
  position: absolute;
  top: @space-text;
  left: 0; /* rtl: ignore */
}
.locate-label__upper-right {
  position: absolute;
  top: @space-text;
  right: 0; /* rtl: ignore */
}
.locate-label__lower-left {
  position: absolute;
  bottom: @space-text;
  left: 0; /* rtl: ignore */
}
.locate-label__lower-right {
  position: absolute;
  bottom: @space-text;
  right: 0; /* rtl: ignore */
}
.locate-label__type-img {
  width: 100%;
  height: auto;
  &.locate-label__lower-left {
    bottom: @space-img;
  }
   &.locate-label__lower-right {
    bottom: @space-img;
  }
  &.locate-label__upper-left{
    top: @space-img;
  }
  &.locate-label__upper-right{
    top: @space-img;
  }
}
.locate-label__move-up {
  bottom: .32rem;
}
</style>

