<script lang="jsx">
import { transformImg } from '@shein/common-function'

const DEFAULT_IMG_POSITION = {
  left: '.4rem',
  top: '9.3333rem'
}

export default {
  name: 'AddPopAnimation',
  props: {
    originImage: {
      type: String,
      default: ''
    },
    showFloatCartIcon: {
      type: Boolean,
      default: false
    },
    showTopCartIcon: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      imagePosition: {
        left: DEFAULT_IMG_POSITION.left,
        top: DEFAULT_IMG_POSITION.top,
        minHeight: '10rem',
      }
    }
  },
  mounted() {
    window.vBus && window.vBus.$on('triggerAddCompletedAnimation', this.triggerAddCompletedAnimation)
  },
  beforeDestroy() {
    window.vBus && window.vBus.$off('triggerAddCompletedAnimation', this.triggerAddCompletedAnimation)
  },
  methods: {
    triggerAddCompletedAnimation () {
      if(this.showFloatCartIcon) {
        window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: true })
      } else if(this.showTopCartIcon) {
        this.handleTriggerAddCompletedAnimationTop()
      } else {
        this.$emit('sucTip')
      }
    },
    handleTriggerAddCompletedAnimationTop () {
      let { x, y } = $('.header-bag')?.[0]?.getBoundingClientRect?.() || {}
      if (x && y) {
        this.imagePosition.left = x + 12 + 'px'
        this.imagePosition.top = y + 12 + 'px'
      } else {
        this.imagePosition.left = DEFAULT_IMG_POSITION.left
        this.imagePosition.top = DEFAULT_IMG_POSITION.top
      }
      this.$nextTick(() => {
        $('.productDetail-addCompletedImg').removeClass('mshe-hide').addClass('add-completed-keyframes')
        setTimeout(() => {
          // 重置位置
          this.imagePosition = {
            left: '.4rem',
            top: '9.3333rem',
            minHeight: '10rem',
          }
          $('.productDetail-addCompletedImg').removeClass('add-completed-keyframes').addClass('mshe-hide')
        }, 500)
      })
    }
  },
  render() {
    const { originImage, imagePosition } = this
    // 商品加车动画
    const renderPopAnimationBox = () => {
      if (!originImage) return null
      return (
        <img
          src={transformImg({ img: originImage })}
          class="productDetail-addCompletedImg mshe-hide"
          style={{
            ...imagePosition
          }}
        />
      )
    }

    return renderPopAnimationBox()
  }
}

</script>

<style lang="less">

.productDetail-addCompletedImg {
  width: 10rem;
  display: block;
  position: fixed;
  top: 0;
  opacity: .5;
  left: 9.3333rem; // 这里改成购物车 left + 11px 默认 350px
  top: .4rem; // 这里改成购物车 top 默认 15px
  z-index: 10000; /* stylelint-disable-line */
  &.mshe-hide {
    display: none !important; /* stylelint-disable-line */
  }
  &.add-completed-keyframes {
      animation-name: product-detail-add-completed-transform-keyframes;
      animation-duration: 500ms;
      animation-delay: 0ms;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      transform-origin: 0 0;
  }
}

@keyframes product-detail-add-completed-transform-keyframes {
  0% {transform: translateX(-8.6rem) scale(1) ;animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform: translateX(0) scale(0);}
}

</style>
