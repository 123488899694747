import { daEventCenter } from './index'

const exposeMixin = {
  data() {
    return {
      currentPath: ''
    }
  },
  mounted() {
    this.currentPath = location.pathname
  },
  destroyed() {
    if (!daEventCenter.exposeCodeMap.has(this.currentPath)) return
    const uniqueCodes = daEventCenter.exposeCodeMap.get(this.currentPath)
    uniqueCodes.forEach((code) => {
      daEventCenter.getExposeInstance().remove(code)
    })
    uniqueCodes.clear()
    daEventCenter.exposeCodeMap.delete(this.currentPath)
  },
}

export { exposeMixin }
