import CommentReportPopover from 'public/src/pages/goods_detail/components/middle/innerComponents/Reviews/components/Comment/components/CommonReport/report/index.js'
function changeHash (value) {
  location.hash = value ? '#' + value : ''
}

const backClass = '.j-icon-back, .check-size-panel .icon-close, .c-drawer .sui_icon_nav_back_24px, .icon-back, .mshe-mask, .j-clear-history-hash, .S-modal.S-modal__drawer, .S-drawer:not(.keep-hash)'




function handleHash () {
  $(document).on('click', backClass, function (e) {
    if ($(this).is('.j-header-bag')) return
    const _isDrawer = $(this).hasClass('S-drawer')
    if (_isDrawer && e.currentTarget !== e.target) return
    // 快速加车的弹窗关闭不会退hash
    const _isQuickAdd = _isDrawer && e.currentTarget?.children?.[0]?.className?.indexOf('c-quick-add-container') > -1
    if (_isQuickAdd) return
    // 举报蒙层关闭不会退hash
    const isReportDrawer = _isDrawer && e.currentTarget?.className?.indexOf('report-drawer-container') > -1
    // 尺码选择弹窗关闭不会退hash
    const isLocalSizeSelectDrawer = _isDrawer && $(this).hasClass('c-drawer-local-size-select')
    if (isReportDrawer || isLocalSizeSelectDrawer) return

    if (location.hash) {
      history.go(-1)
      return false
    }
  })

  $(document).on('click', '.j-push-history-hash', function () {
    var $this = $(this)
    var state = $this.attr('state')
    changeHash(state)
  })

  const hashChangeCallback = (function(){
    var hashValue = location.hash
    hashValue = hashValue.replace('#', '')
    switch (hashValue) {
      case 'delivery':
        this.updateDeliveryStatus(true)
        // changeStatus['delivery'] = true
        break
      case 'return-policy':
        this.updateReturnPolicyStatus(true)
        // changeStatus['returnPolicy'] = true
        break
      case 'cod-policy':
        this.updateCodPolicyStatus(true)
        // changeStatus['codPolicy'] = true
        break
      case 'report':
        this.updateReportStatus(true)
        this.updateReportFormStatus(false)
        // changeStatus['report'] = true
        break
      case 'reportOld':
        this.updateReportStatusOld(true)
        // changeStatus['report'] = true
        break
      case 'reportForm':
        this.updateReportFormStatus(true)
        break
      case 'sizeGuide':
        this.updateShowSizeGuideDrawer(true)
        // changeStatus['showSizeGuide'] = true
        break
      case 'description':
        this.updateDescriptionStatus(true)
        // changeStatus['description'] = true
        break
      case 'userManual':
        this.updateUserManualStatus(true)
        // changeStatus['description'] = true
        break
      case 'detailOPos':
        this.updateDetailOPos(true)
        // changeStatus['detailOPos'] = true
        break
      case 'detailFPos':
        this.updateDetailFPos(true)
        // changeStatus['detailFPos'] = true
        break
      case 'lookbook':
        this.updateShoplookLightbox(true)
        // changeStatus['shoplookLightbox'] = true
        break
      case 'comment-all':
        this.openViewCommentPop()
        this.updateShowSortList(false)
        this.updateShowColorSizeList(false)
        this.updateShowRatingList(false)
        // 评论反馈返回评论页时关闭评论弹窗
        CommentReportPopover.close()
        break
      case 'cumtomer-show':
        this.updateCustomerShow(true)
        break
      case 'guarantee':
        this.updateGuaranteeStatus(true)
        break
      case 'goods-video':
        this.updatePlayVimeoStatus(true)
        break
    }
  
    if (!hashValue) {
      this.updateDescriptionStatus(false)
      this.updateUserManualStatus(false)
      this.updateReturnPolicyStatus(false)
      this.updateViewPromotionStatus({
        status: false,
        isQuickAdd: false
      })
      this.updateShoplookLightbox(false)
      this.updateSimilarItemsStatus(false)
      this.updateDeliveryStatus(false)
      this.updateShowSizeGuideDrawer(false)
      this.updateCheckSizeAndSizeGuide?.(false)
      this.updateReportStatus(false)
      this.updateReportStatusOld(false)
      this.updateReportFormStatus(false)
      this.updateCodPolicyStatus(false)
      this.updateDetailOPos(false)
      this.updateDetailFPos(false)
      this.updateShowSortList(false)
      this.updateShowColorSizeList(false)
      this.updateShowRatingList(false)
      this.updateShowReviewImgbox(false)
      this.closeViewCommentPop()
      this.updateDrawerFilterShow(false)
      this.updateGuaranteeStatus(false)
      CommentReportPopover.close()
      this.updateCustomerShow(false)
      this.updatePlayVimeoStatus(false)
      $('.check-size-panel').removeClass('animated-medium slideInUp-modal block')
      $(document.body).removeClass('mshe-scroll-hide')
    }
  }).bind(this)

  // hash change start
  $(window).on('hashchange', hashChangeCallback)
  return {
    hashChangeCallback
  }
}

function hashUnBind ({ hashChangeCallback }) {
  $(document).off('click', backClass)
  $(document).off('click', '.j-push-history-hash')
  $(window).off('hashchange', hashChangeCallback)
}

export {
  handleHash,
  hashUnBind
}
