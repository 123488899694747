import Vue from 'vue'
import Report from './report.vue'
let ReportInstance = null
const CommentReportPopover = {
  open: ({ config, props }) => {
    if (ReportInstance) return ReportInstance.open(config)
    const vm = new Vue({
      render(h) {
        return h(Report, {
          props
        })
      }
    }).$mount()
    document.body.appendChild(vm.$el)
    const Instance = vm.$children[0]
    Instance.open(config)
    ReportInstance = Instance
  },
  close: () => {
    if(ReportInstance) return ReportInstance.closeReport()
  }
}

export default CommentReportPopover
