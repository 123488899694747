<script lang="jsx">
import ClientOnly from 'vue-client-only'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import CommentOverview from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/CommentOverview.vue'

daEventCenter.addSubscriber({ modulecode: '2-24' })

const SharePopup = () => import(/* webpackChunkName: "top_share_popup" */ './components/SharePopup.vue')

const getChannelAnalysis = ({ type, channel }, goodsId) => {
  const data = {
    share_type: 'page',
    content_id: goodsId,
    channel,
  }
  return {
    id: type === 'click' ? '2-24-4' : '2-24-3',
    code: 'ShareCommonChannel',
    once: false,
    data,
  }
}

export default {
  name: 'GoodsName',
  functional: true,
  components: {
    ClientOnly,
    SharePopup,
    CommentOverview
  },
  props: {
    goodsId: {
      type: String,
      default: '',
    },
    isShowMoreIcon: {
      type: Boolean,
      default: false,
    },
    isTitleEllipsis: {
      type: Boolean,
      default: false,
    },
    goodsNameData: {
      type: Object,
      default: () => ({}),
    },
    goodsNameLabels: {
      type: Array,
      default: () => []
    },
    sharePanelData: {
      type: Object,
      default: () => ({}),
    },
    commentOverview: {
      type: Object,
      default: () => ({}),
    },
    showGoodsNameComment: {
      type: Boolean,
      default: false
    },
    isEstimatedShowTypeNew: {
      type: Boolean,
      default: false
    },
    isPriceBannerTypeAbtNew: {
      type: Boolean,
      default: false
    },
    fsAbt: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props, listeners }) {
    let { goodsId, isShowMoreIcon, goodsNameData = {}, goodsNameLabels = [], sharePanelData = {}, isTitleEllipsis, commentOverview = {}, showGoodsNameComment, isEstimatedShowTypeNew, isPriceBannerTypeAbtNew, fsAbt } = props
    const { isBlack, language, goodsName, PUBLIC_CDN_rp, inFashionStoreImg } = goodsNameData || {}
    const { sharePopupReady, sharePanel, goodsLink, productImgs } = sharePanelData

    let pinterestImg
    if (sharePopupReady) {
      pinterestImg = transformImg({ img: productImgs?.[0]?.origin_image?.replace('.jpg', '_thumbnail_405x552.jpg') })
    }
    
    const showTitlesellpoint = ['plana', 'planb'].includes(fsAbt?.titlesellpoint?.p?.titlesellpoint)
    // const hasNew = isPreferredSeller || badgeInfo
    // const hasTrend = showTrendsLabel
    // if (hasNew || hasTrend) {
    //   const isOnlyNew = hasNew && !hasTrend
    //   const isOnlyTrend = hasTrend && !hasNew
    //   daEventCenter.triggerNotice({
    //     daId: '1-6-1-222',
    //     extraData: {
    //       location: 'detail',
    //       label: isOnlyNew ? 'new' : isOnlyTrend ? 'trend' : 'new`trend',
    //     }
    //   })
    // }
    const gettAnalysisData = (key) => {
      return {
        directives: [
          {
            name: 'expose',
            value: {
              id: '1-6-1-222',
              data: { key },
              code: 'expose—title-label-events',
            }
          }
        ]
      }
    }
    return (
      <div 
        class={['goods-name', isBlack ? 'goods-name__black' : '']}
      >
        <h1
          ref="goodsDetailTitle"
          class={[
            'detail-title-h1',
            goodsNameLabels.length ? 'new-line-height' : '',
            isShowMoreIcon && isTitleEllipsis ? 'title-line-camp' : '',
            showTitlesellpoint && isShowMoreIcon ? 'title-line-sellpoint' : 'title-line-old',
          ]}
          vOn:click={listeners && listeners['toogleGoodsNameEllipsis']}
        >
          {
            goodsNameLabels.map(item => {
              if(item.label === 'isPreferredSeller'){
                { /* 优选卖家标签 */ }
                return (
                  <span
                    {...{ directives: [{ name: 'expose', value: { id: '1-6-1-99' } }] }}
                    aria-hidden="true"
                    class="goods-name__label goods-name__preferred-seller"
                  >
                    {language.SHEIN_KEY_PWA_20758}
                  </span>
                )
              } else if(item.label === 'isNew'){
                { /* new标签 */ }
                return (
                  <span
                    {...gettAnalysisData('new')}
                    aria-hidden="true"
                    class="goods-name__label"
                    style={{
                      color: item?.badgeInfo?.fontColor,
                      background: item?.badgeInfo?.backgroundColor,
                    }}
                  >
                    {item?.badgeInfo?.name}
                  </span>
                )
              }else if(item.label === 'showTrendsLabel'){
                { /* 趋势标 */ }
                return (
                  <span 
                    {...gettAnalysisData('trend')}
                    class="goods-name__fashionIcon" 
                  >
                    {
                      inFashionStoreImg ? 
                        <img class="goods-name__fashionIcon-img" src={inFashionStoreImg} /> :
                        <span class="goods-name__fashionIcon-default">
                          { language.SHEIN_KEY_PWA_30166 || 'Trends' }
                          <img
                            class="goods-name__fashionIcon-icon"
                            src={`${PUBLIC_CDN_rp}/pwa_dist/images/store/infashion_label/default-fd2954326c.png`}
                          />
                        </span>
                    }
                  </span>
                )
              }else if(item.label === 'isEvolushein'){
                return (
                  <span
                    aria-hidden="true"
                    class="goods-name__label goods-name__evolushein"
                  >
                    evoluSHEIN
                  </span>
                )
              }else if(item.label === 'isShowBrand'){
                { /* brand标 */ }
                return (
                  <span
                    aria-hidden="true"
                    class="goods-name__label goods-name__brand"
                  >
                    { language.SHEIN_KEY_PWA_24319 }
                  </span>
                )
              }
            })
          }
          {
            showTitlesellpoint && isShowMoreIcon && isTitleEllipsis && (
              <span class="sellpoint-icon sellpoint-icon_down">
                <i
                  class="sui_icon_more_down_12px_2 suiiconfont"
                ></i>
              </span>
            )
          }
          <span class={['detail-title-text', 'fsp-element', showTitlesellpoint ? 'title-text-bold' : '']} role="text" tabindex="1" aria-label={goodsName}>
            {goodsName}
          </span>
          {!showTitlesellpoint && isShowMoreIcon && (
            <i
              class={[isTitleEllipsis ? 'sui_icon_more_down2_16px' : 'sui_icon_more_up2_16px', 'suiiconfont', 'title-expand-icon']}
            ></i>
          )}
          {showTitlesellpoint && isShowMoreIcon && !isTitleEllipsis && (
            <i
              class="sui_icon_more_up_12px_2 suiiconfont sellpoint-icon sellpoint-icon_up"
            ></i>
          )}
        </h1>
        {/* shein站点满足条件显示评论入口 & romwe站点满足条件显示分享入口 */}
        {showGoodsNameComment ? 
          <div
            class="goods-name__comment"
            vOn:click={listeners && listeners['handleClickComment']}
          >
            <CommentOverview
              total-comment={commentOverview.totalComment}
              comment-info={commentOverview.commentOverView}
              type="small"
              language={language}
              
            />
          </div>
          :
          (!isEstimatedShowTypeNew && !isPriceBannerTypeAbtNew  ?
            <i
              ref="openShare"
              {...{
                directives: [
                  {
                    name: 'tap',
                    value: {
                      id: '2-24-2',
                      data: {
                        share_type: 'page',
                        content_id: goodsId,
                      },
                    },
                  },
                  {
                    name: 'expose',
                    value: {
                      id: '2-24-1',
                      data: {
                        share_type: 'page',
                        content_id: goodsId,
                      },
                    },
                  },
                ],
              }}
              tabindex="1"
              class="suiiconfont sui_icon_share_20px"
              vOn:keyup-enter={listeners && listeners['showShareChannel']}
              vOn:click={listeners && listeners['showShareChannel']}
            ></i> : null)
        }
        <ClientOnly>
          {sharePopupReady && (
            <SharePopup
              share-panel={sharePanel}
              goods-link={goodsLink}
              public-cdn-rp={PUBLIC_CDN_rp}
              pinterest-img={pinterestImg}
              get-channel-analysis={(p) => getChannelAnalysis(p, goodsId)}
              vOn:closeSharePopup={listeners && listeners['closeSharePopup']}
            />
          )}
        </ClientOnly>
      </div>
    )
  },
}
</script>

<style lang="less">
.goods-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.10667rem;
  width: 100%;
  &__comment {
    align-self: flex-start;
    margin-left: 0.32rem;
  }
  &__label {
    display: inline-block;
    margin-right: 0.11rem;
    padding: 0 0.08rem;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 0.05333rem;
    font-size: 11px;
    /*rw:begin*/
    padding: 0 5px;
    font-family: 'Adieu';
    font-weight: 700;
  }
  &__preferred-seller{
    background: #fff8eb;
    color: @sui_color_micro_emphasis;
  }
  &__fashionIcon{
    display: inline-block;
    height: 16px;
    line-height: 16px;
    margin-right: 0.11rem;
    img {
      height: 100%;
    }
  }
  &__fashionIcon-img{
    vertical-align: top;
  }
  &__fashionIcon-default{
    position: relative;
    display: inline-block;
    padding: 0 5px 0 2px;
    font-size: 11px;
    font-style: italic;
    font-weight: 858;
    color: #fff;
    z-index: 1;
    height: 100%;
  }
  &__fashionIcon-icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &__evolushein{
    background: linear-gradient(85deg, #FFEEDA -7.76%, #FFF2E1 39.69%, #FFF3E6 97.47%);
    color: #A86104;
    text-transform: none; // 取消首字母大写
  }
  &__brand{
    color: #FFF;
    font-weight: 510;
    background: linear-gradient(90deg, #749CD8 -1.43%, #9BBAEA 100%);
    text-transform: none;
  }
  > h1 {
    position: relative;
    .font-dpr(24px);
    color: #666;
    line-height: 14px;
    user-select: text;
    vertical-align: middle;
    font-weight: normal;
    .detail-title-text {
      word-break: break-all;
    }
    .title-text-bold{
      font-weight: 510;
      color: #161616;
    }
    &.new-line-height {
      line-height: 16px;
    }
    .title-expand-icon {
      position: absolute;
      bottom: -3px;
      right: 0px;
      background: #fff;
      box-shadow: -10px 0px 10px 3px #fff;
    }
  }
  .title-line-old.title-line-camp{
    .line-camp();
  }
  .title-line-sellpoint.title-line-camp{
    max-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
  }
  .title-line-sellpoint{
    &::before{
      content: '';
      float: right;
      height: 14px;
    }
    .sellpoint-icon{
      width: 14px;
      text-align: center;
      color: #000;
    }
    .sellpoint-icon_down{
      float: right;
      clear: both;
      position: relative;
      margin-left: 0.26666rem;
      &::before {
        content: '...';
        position: absolute;
        left: 0;
        color: #333;
        transform: translateX(-100%)
      }
    }
    .sellpoint-icon_up{
      vertical-align: middle; 
      display: inline-block;
    }
  }
  .new-line-height.title-line-camp{
    max-height: 32px;
    
  }
  .new-line-height.title-line-sellpoint{
    &::before{
      content: '';
      float: right;
      height: 16px;
    }
    .sellpoint-icon_up{
      vertical-align: baseline;
    }
  }
  .sui_icon_share_20px {
    vertical-align: middle;
    .txt-r();
    width: auto;
    height: auto;
    color: #222;
    margin-left: 0.32rem;
  }
  &__black {
    > h1 {
      color: #222;
    }
  }
}
</style>
