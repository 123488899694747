
import { mapMutations } from 'vuex'
import { parseQueryString, getLocalStorage, setLocalStorage } from '@shein/common-function'

const originMarkMapPosKey = {
  Y1: 'Itemoutdetail1',
  Y2: 'Itemoutdetail2',
  Y3: 'Itemoutdetail3',
  Y4: 'Itemoutdetail4',
  Y5: 'Itemoutdetail5',
}
export default {
  data () {
    return {
      // 来源标示
      originMark: '', // Y1, Y2, Y3, Y4, Y5, X
      // 站外商详分流PosKey
      outSitePosKey: '',
      abtInfo: {},
      // url qeury 匹配到打标后缀
      isMatchUrlFrom: false,
      // 是否折叠详情
      isFoldDetail: false,
      // 是否是站外详情页
      outSiteGoodsDetail: false,
      // sa 上报用
      customerType: ''
    }
  },
  beforeRouteUpdate (to, from, next) {
    // fix 侧边栏打开/关闭 ，url 新增/删除 hash
    if (to.params[1] == from.params[1]) return
    this.isMatchUrlFrom = false
    this.isFoldDetail = false
    next()
  },
  created () {
    if (typeof window === 'undefined') {
      return
    }
    this.getOriginMark()
    this.outSitePosKey = originMarkMapPosKey[this.originMark] || ''
  },
  methods: {
    ...mapMutations('productDetail', ['assignState']),
    ...mapMutations('newProductDetail/common', ['updateIsFoldDetail', 'updateIsOutSite']),
    getOriginMark () {
      const queryArgObj = parseQueryString(location.search) || {}
      const urlFrom = queryArgObj.url_from || ''
      let originMark = 'X'
      // 判断后缀
      // url有后缀标示广告过来，折叠详情
      switch (true) {
        // Bing-pla渠道
        case !!urlFrom.match(/bingpla-/g):
          originMark = 'Y1'
          break
        // Google-PLA渠道
        case !!urlFrom.match(/.*adpla((?!rmt))((?!\-).*)(_shc|_ssc)?|.*adpla((?!rmt)).*(-pla-.*)(_shc|_ssc)?/g):
          originMark = 'Y2'
          break
        // Google-RMT渠道
        case !!urlFrom.match(/.*adplarmt|.*adpla.*plarmt.*/g):
          originMark = 'Y3'
          break
        // Criteo渠道
        case !!urlFrom.match(/.*adct_.*/g):
          originMark = 'Y4'
          break
        // Facebook-dpa渠道
        case !!urlFrom.match(/fbads.*dpa|.*dyfbrmt/g):
          originMark = 'Y5'
          break
      }
      if (originMark !== 'X') this.isMatchUrlFrom = true

      // get origin mark from cookie value
      let originMarkFromcookie = null
      const originMarkCookieKey = 'goods_detail_origin_mark'
      // check cookie has value
      originMarkFromcookie = getLocalStorage({ key: originMarkCookieKey })
      // The cookie value is preferred
      if (originMarkFromcookie) {
        originMark = originMarkFromcookie
      } else {
        setLocalStorage({ key: originMarkCookieKey, value: originMark, expire: 1000 * 60 * 60 * 24 * 90  })
      }
      this.originMark = originMark
    },
    async getOutSiteAbtResult () {
      if (this.originMark === 'X') {
        this.customerType = 'X'
      } else {
        // Y1 - Y5
        if (this.isMatchUrlFrom) this.isFoldDetail = true
        this.outSiteGoodsDetail = true
        this.customerType = `${this.originMark}_`
      }
      
      this.setData()
    },

    setData () {
      this.updateIsFoldDetail(this.isFoldDetail)
      this.updateIsOutSite(this.outSiteGoodsDetail)
    }
  }
}
