<script lang="jsx">
import PlaceholderGoodsSize from './components/PlaceholderGoodsSize'
import SizeBox from '../SizeBox'
import SaleAttrsFoldComp from '../SaleAttrsFold/index.vue'
import Quantity from './components/Quantity'
import ClientOnly from 'vue-client-only'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapState, mapGetters, mapActions } from 'vuex'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })


export default {
  name: 'TopOther',
  components: {
    Quantity,
    PlaceholderGoodsSize,
    SizeBox,
    CatSelectionTagEnter: () =>
      import(
        /* webpackChunkName: "CatSelectionTagEnter" */ 'public/src/pages/goods_detail/components/middle/innerComponents/MiddleAttr/components/CatSelectionTagEnter.vue'
      ),

  },
  data() {
    return {
      // rankingLink: '',
      showSelectionDrawer: false,
    }
  },
  computed: {
    // todo后续迁移新数据结构
    // ...mapState('productDetail', ['topRankingInfo']),
    ...mapState('newProductDetail/common', ['fixedRatio', 'pageCommonInfo']),
    ...mapGetters('newProductDetail/common', ['language', 'detail', 'pageCommonInfo', 'saleAttrsFoldAb', 'getSkcEstimatedInfo']),
    ...mapGetters('newProductDetail/TopOther', [
      'showCatSelectionTagTop',
      'topOtherBase',
      'showQuantity',
      // 'abtRankAggrega',
      // 'RankAggrega',
      'showSizePlaceHolder',
      'cacheSaleAttrList'
    ]),
    ...mapGetters('newProductDetail/SaleAttrsFold', ['compShowConfig']),
    ...mapGetters('newProductDetail', ['fsAbt', 'isPerformance', 'cccCatSelectionTag']),
  },
  methods: {
    ...mapActions('newProductDetail', ['openDescriptionPop']),
    handleSelectionDrawer(status) {
      if (status) {
        daEventCenter.triggerNotice({ daId: '1-6-4-72' })
        daEventCenter.triggerNotice({ daId: '1-6-4-73' })
      }
      this.showSelectionDrawer = status
    },
  },
  render() {
    // common
    const {
      topOtherBase = {},
      showQuantity,
      showSizePlaceHolder,
      showCatSelectionTagTop,
      cccCatSelectionTag,
      pageCommonInfo,
      saleAttrsFoldAb,
      showSelectionDrawer,
      handleSelectionDrawer,
      getSkcEstimatedInfo
    } = this
    const { language } = topOtherBase

    const renderQty = () => {
      if (saleAttrsFoldAb && !this.compShowConfig?.showMallAndQty) return null
      if (getSkcEstimatedInfo?.isSatisfiedBuyMultiple || showQuantity) {
        return <Quantity language={language} /> 
      }
    }

    return (
      <div class={{
        'goods-detail__top-other': true,
        'goods-detail__top-otherFold': saleAttrsFoldAb,
      }}>
        {/* 旧尺码组件 <Sizes></Sizes> 对照 */}
        {/* 新尺码组件 <SizeBox />*/}
        {saleAttrsFoldAb ? <ClientOnly><SaleAttrsFoldComp /></ClientOnly> : null}
        {!saleAttrsFoldAb && (showSizePlaceHolder ? <PlaceholderGoodsSize size-info={this.cacheSaleAttrList}/> : <SizeBox />)}
        { renderQty() }
        {showCatSelectionTagTop && 
          <CatSelectionTagEnter
            quality-floor="NEW"
            tag-data={cccCatSelectionTag}
            page-common-info={pageCommonInfo}
            show-selection-drawer={showSelectionDrawer}
            language={language}
            vOn:handleSelectionDrawer={handleSelectionDrawer}
          />
        }
      </div>
    )
  },
}
</script>

<style lang="less">
.goods-detail__top-other {
  padding-bottom: 0.426667rem;
}
.goods-detail__top-otherFold {
  padding-bottom: 0.27rem;
}
</style>
