var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.compShowConfig.showFoldEntry)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id:'1-6-1-223',
  }),expression:"{\n    id:'1-6-1-223',\n  }"},{name:"tap",rawName:"v-tap",value:({
    id:'1-6-1-224',
  }),expression:"{\n    id:'1-6-1-224',\n  }"}],staticClass:"attrs-fold__content",on:{"click":_vm.openQuick}},[_c('div',{staticClass:"attrs-fold__content-box"},[_c('div',{staticClass:"attrs-fold__content-title"},[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_31281 || 'Options:')+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"attrs-fold__content-listBox"},[_c('div',{staticClass:"attrs-fold__content-list"},_vm._l((_vm.saleAttrsArr),function(item,index){return _c('span',{key:index},[_c('span',{staticClass:"attrs-fold__content-item",class:{
              active: _setup.isActive(item),
            }},[_vm._v(" "+_vm._s(_setup.saleStr(item)))]),_vm._v(" "),(index !== _vm.saleAttrsArr.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),0),_vm._v(" "),(_vm.sizeStockTips)?_c('div',{staticClass:"attrs-fold__content-soldout entry-soldout"},[_c('Icon',{attrs:{"name":"sui_icon_hourglass_12px_2","size":"12px"}}),_vm._v("\n        "+_vm._s(_vm.soldOutTips)+"\n      ")],1):_vm._e()])]),_vm._v(" "),(_vm.colorsFoldInfo.showColorList)?_c(_setup.SkcRadios,{attrs:{"colorsFoldInfo":_vm.colorsFoldInfo}}):_vm._e(),_vm._v(" "),_c(_setup.NewTip,{attrs:{"showHint":_vm.showHint,"language":_vm.language,"closeHint":_vm.closeHint}})],1):(_vm.compShowConfig.stockTipLocation === 'line' && _vm.sizeStockTips)?_c('div',{staticClass:"attrs-fold__content-soldout"},[_c('Icon',{attrs:{"name":"sui_icon_hourglass_12px_2","size":"12px"}}),_vm._v("\n  "+_vm._s(_vm.soldOutTips)+"\n")],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }