<script lang="jsx">
/**
 * @component 加车按钮内底部行价格展示
 */
import { template } from '@shein/common-function'
const { IS_RW } = gbCommonInfo

export default {
  name: 'BtnContentWithPrice',
  functional: true,
  props: {
    // 是否展示价格
    showPrice: {
      type: Boolean,
      default: false,
    },
    // 是否展示利益点文案
    showBenefitText: {
      type: Boolean,
      default: true,
    },
    // 展示类型，与abt [QuickShow] 的值一致
    showType: {
      type: String,
      default: '',
    },
    defaultBtnText: {
      type: String,
      default: '',
    },
    // 价格信息
    priceInfo: {
      type: Object,
      default: () => ({}),
    },
    // 是否评论弹窗内
    isReview: {
      type: Boolean,
      default: false,
    },
    // 曝光abt
    exposeAbt: {
      type: String,
      default: '',
    },
    // 使用折扣样式
    useDiscountStyle: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    getEstimatedInfo: {
      type: Object,
      default: null
    },
    fsAbt: {
      type: Object,
      default: () => ({})
    },
    countdownTips: {
      type: String,
      default: '',
    },
    isShowCountdown: {
      type: Boolean,
      default: false,
    },
    isInversion: {
      type: Boolean,
      default: false,
    },
    complianceMode: {
      type: Boolean,
      default: false,
    },
    complianceTipsMode: {
      type: Boolean,
      default: false,
    },
    bestDealConfig: {
      type: Object,
      default: () => ({})
    },
    soldoutState: {
      type: Object,
      default: () => ({})
    },
    // 闪购倒计时
    flashCountdownTips: {
      type: String,
      default: '',
    },
    isShowflashCountdown: {
      type: Boolean,
      default: false,
    },
    discountPercent: {
      type: String,
      default: '',
    },
    // todo Jason
    // 命中价格置顶实验quickprice=Ceiling时，加车按钮只展示利益点内容，无需展示价格
    isDetailBuyNowPriceCeiling: {
      type: Boolean,
      default: false,
    },
    // CCC返回的内容顺序及内容标识，判断是否具备利益点文案展示的条件
    cccAddCartPrompt: {
      type: Array,
      default: () => ([]),
    },
    complianceModeDe: {
      type: Boolean,
      default: false,
    },
    isStrengthen: {
      type: Boolean,
      default: false,
    },
    isBrandFlash: {
      type: Boolean,
      default: false,
    },
    showPriceBelt: {
      type: Boolean,
      default: false,
    },
    isPaidUser: {
      type: Boolean,
      default: false,
    },
    sheinClubPromotionInfo:  {
      type: Object,
      default: () => null
    },
    isHitComplianceMode: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, listeners }) {
    let {
      showBenefitText,
      defaultBtnText,
      showPrice,
      priceInfo,
      exposeAbt,
      isReview,
      language,
      useDiscountStyle,
      showType,
      getEstimatedInfo,
      countdownTips,
      isShowCountdown,
      isInversion,
      complianceMode,
      bestDealConfig,
      soldoutState,
      flashCountdownTips,
      isShowflashCountdown,
      discountPercent,
      cccAddCartPrompt,
      isDetailBuyNowPriceCeiling,
      complianceTipsMode,
      complianceModeDe,
      isStrengthen,
      isBrandFlash,
      showPriceBelt,
      isPaidUser,
      sheinClubPromotionInfo,
      isHitComplianceMode
    } = props
    // 仅仅 B 在线
    if (showType === 'B') {
      if (!priceInfo) return null

      const {
        showFrom,
        salePrice,
        showDisCountPrice,
        isSpecialFlash,
        showVipStyle,
      } = priceInfo

      const saleHtml = showFrom
        ? template(
        `<span class="sale-num">${salePrice}</span>`,
        language.SHEIN_KEY_PWA_17812
        )
        : `<span class="sale-num">${salePrice}</span>`

      // 零售价相关
      const showSalePrice = !!salePrice
      const discountStyle = showSalePrice
      && (
        showDisCountPrice
        || isSpecialFlash
        || useDiscountStyle
      )

      // 到手价
      let estimatedPriceInfo = null
      if (getEstimatedInfo && !!getEstimatedInfo?.isSatisfied && !!getEstimatedInfo?.value) {
      
        let price = getEstimatedInfo?.value?.amountWithSymbol
        let text = template(
          isStrengthen ? `<b>${price}</b>` : `<span>${price}</span>`,
          isStrengthen ? language.SHEIN_KEY_PWA_31593 || `Est. {0}`: language.SHEIN_KEY_PWA_24939,
        ) 
        estimatedPriceInfo = {
          text,
          isPaidVip: showVipStyle
        }
      }

      // 划线价相关、未满足门槛到手价不显示划线价
      let retailPrice = ''
      if (showDisCountPrice && !isInversion && priceInfo.retailPrice && !complianceTipsMode && !complianceModeDe) {
        retailPrice = priceInfo.retailPrice
      }
      if (!!estimatedPriceInfo) retailPrice = null
      if (!!getEstimatedInfo) {
        let estimatedInfo = getEstimatedInfo.estimatedCompProps
        if (!!estimatedInfo?.price?.addOnAmountWithSymbol) {
          retailPrice = null
        }
      }
      const benefitMap = {
        // 库存不足
        inventory() {
          const { showSoldOutTips, soldOutTips } = soldoutState
          return showSoldOutTips ? soldOutTips : ''
        },
        // 到手价倒计时 ||  闪购倒计时
        countdown() {
          if(isShowCountdown) {
            return countdownTips
          }
          if(isShowflashCountdown) {
            return flashCountdownTips
          }
        },
        // 同款最低价 
        sameitemprice() {
          return bestDealConfig?.sameitemprice?.contentText || ''
        },
        // 历史最低价
        historyprice() {
          return bestDealConfig?.historyprice?.contentText || ''
        },
        // 折扣说明
        normaldiscount() {
          if(discountPercent) {
            return template(
              discountPercent,
              language.SHEIN_KEY_PWA_31469,
            )
          }
        }
      }

      let strategyStyle = { color: '', icon: ''}
      if(isStrengthen && showBenefitText){ // 不是双按钮才强化颜色
        if(isHitComplianceMode){ // 合规价时，写死白色
          strategyStyle.color = '#FFFFFF'
        } else if (isPaidUser && sheinClubPromotionInfo){ // 满足已开通会员价的颜色
          strategyStyle.color = '#FFBA97'  
        } else if(showPriceBelt && isBrandFlash){ // 满足品牌特卖的颜色
          strategyStyle.color = '#FF8957'
        } else if (showPriceBelt && !isBrandFlash && !isSpecialFlash){  // 满足闪购活动的颜色（剔除新人闪购，特殊闪购）
          strategyStyle.color = '#FFEDA2' 
        } else { // 默认强化为白色
          strategyStyle.color = '#FFFFFF'
        }
      }
      let curPromptCode = '' // 当前展示的利益点code
      const benefitText = (() => {
        if(!cccAddCartPrompt.length || !showBenefitText) return ''
        
        let result = ''
        for (let i = 0; i < cccAddCartPrompt.length; i++) {
          const item = cccAddCartPrompt[i]
          if (benefitMap[item.promptCode]) {
            result =  benefitMap[item.promptCode]()
            if(result){
              curPromptCode = item.promptCode
              listeners?.benefitText(curPromptCode)

              const iconType = item.iconType || []
              const isDynamicImg = item.iconPickType == 2
              const isStaticImg = item.iconPickType == 1
              if(isStrengthen && (isStaticImg || isDynamicImg)){
                const imgType = isStaticImg ? 'staticImg' : 'dynamicImg' // 判断是取静态图还是动态图
                if(isHitComplianceMode){ // 合规价时，写死白色
                  strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'normalicon')?.[imgType] || ''
                } else if (isPaidUser && sheinClubPromotionInfo){ // 满足已开通会员价的sheinclubicon
                  strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'sheinclubicon')?.[imgType] || ''
                } else if(showPriceBelt && isBrandFlash){ // 满足品牌特卖的brandsaleicon
                  strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'brandsaleicon')?.[imgType] || ''
                } else if (showPriceBelt && !isBrandFlash && !isSpecialFlash){  // 满足闪购活动的flashsaleicon（剔除新人闪购，特殊闪购）
                  strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'flashsaleicon')?.[imgType] || ''
                } else  { // 默认normalicon
                  strategyStyle.icon = iconType?.find?.(it => it.iconCode == 'normalicon')?.[imgType] || ''
                }
              }
              return result
            } 
          }
        }
        return result
      })()

      const exposeValue = {
        id: '1-6-1-128',
        once: true,
        data: {
          abtest: exposeAbt,
          review_location: isReview ? 'page_review' : '-',
          interestpoints: curPromptCode
        }
      }

      return (
        <div 
          class="add-cart__btn-contentwithprice type-b"
          style={{ color: strategyStyle?.color }}
        >
          {!isStrengthen && <div class={{ 'text': showPrice, 'add-carttext-style': true }}>
            { defaultBtnText }
          </div>}
          <div
            {...{ directives: [{ name: 'expose', value: exposeValue }] }}
            class={{
              'type-b-price': true,
              'strengthen-to-top': isStrengthen
            }}
            style={{ display: showPrice ? '' : 'none' }}
          >
            { !isDetailBuyNowPriceCeiling &&
            (
              (estimatedPriceInfo && !(complianceMode || complianceTipsMode)) ? 
                <div 
                  class={{
                    'estimated-price': true,
                    'estimated-price-vip': estimatedPriceInfo.isPaidVip
                  }}
                  style={{ color: strategyStyle?.color }}
                  domPropsInnerHTML={estimatedPriceInfo.text}
                >
                  {/* { estimatedPriceInfo.text } */} 
                  {/* {isShowCountdown && <div 
                      class="add-cart__count-down" 
                      domPropsInnerHTML={countdownTips}
                    ></div>} */}
                </div>
                : salePrice && 
                <div
                  class={{
                    'discount': !IS_RW && discountStyle && !isInversion && !complianceTipsMode && !complianceModeDe,
                    'vip': showVipStyle && !complianceTipsMode && !complianceModeDe,
                  }}
                  style={{ color: strategyStyle?.color }}
                  domPropsInnerHTML={saleHtml}
                ></div>
            )
            }
            {(!isDetailBuyNowPriceCeiling && retailPrice && !isShowCountdown && !benefitText) && (
              <div 
                class="retail"
              >
                { retailPrice }
              </div>
            )}
            {/* 利益点文案 */}
            {
              benefitText && showBenefitText && strategyStyle?.icon ? (
                <img class="benefit-icon" src={strategyStyle?.icon} />
              ) : null
            }
            {
              benefitText && showBenefitText ? (
                <div 
                  class={{ 'benefit': true, 'has-icon': strategyStyle?.icon }}
                  domPropsInnerHTML={benefitText}
                >
                </div>
              ) : null
            }
          </div>
          {isStrengthen && <div class={{ 'text': showPrice, 'add-carttext-style': true, 'has-benefit-text': benefitText && showBenefitText }}>
            { defaultBtnText }
          </div>}
        </div>
      )
    }

    return (
      <p class="add-carttext-style">{ defaultBtnText }</p>
    )
  }
}
</script>

<style lang="less">
.add-cart__btn-contentwithprice {
  &.type-b {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;

    .text {
      font-size: 15px;
      &.has-benefit-text{
        font-size: 12px;
      }
    }

    .retail{
      color: @sui_color_gray_light2;
      font-size: 12px;
      text-decoration-line: line-through;
      margin-left: 4px;
    }
    .benefit-icon{
      width: 0.32rem;
      height:0.32rem;
      margin: 0 0.053rem 0 0.2667rem;
    }
    .benefit{
      // color: rgba(255, 255, 255, 0.80);
      font-weight: 400;
      opacity: 0.80;
      text-align: center;
      margin-left: 0.11rem;
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.has-icon{
        margin-left: 0;
      }
    } 
  }
  .type-b-price {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    text-transform: none;
    &.strengthen-to-top{
      margin-top: 0px;
      margin-bottom: 2px;
      font-size: 14px;
      .estimated-price{
        font-size: 14px;
      }
      .retail{
        font-size: 11px;
      }
    }

    .discount {
      color: @sui_color_promo;
      font-weight: 700;
    }
  
    .vip {
      color: @sui_color_club_rosegold;
    }
  }

  .estimated-price {
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    color: @sui_color_promo;
    text-transform: none;
    // display: flex;
    // align-items: center;
  }
  .estimated-price-vip {
    color: @sui_color_club_rosegold;
  }
  .add-cart__count-down{
    .font-dpr(22px);
    color: rgba(255, 255, 255, 0.80);
    margin-left: 4px;
  }
}
.add-carttext-style {
  width: 100%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>
