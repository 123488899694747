import goodsExpose from '../../../services/goodsExpose'
// import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from '../../../services/eventCenter'
import { getAod } from 'public/src/pages/common/biz_helper/aod'
import { getQueryString } from '@shein/common-function'
daEventCenter.addSubscriber({ modulecode: '1-6-2' })

import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'

let scroll_timeout
function setAnalyisParams () {
  SaPageInfo.page_name = 'page_goods_detail'
  const aod_id = getAod() || '0'
  SaPageInfo.page_param.aod_id = aod_id
  sa('set', 'setPageData', SaPageInfo)
  sa('send', 'pageEnter', { start_time: Date.now() })
}
async function refreshAnalyisParams ({ goods_id, detailAbt }) {
  
  // 设置ga pv发送自定义维度
  // 相似推荐syte
  // const abtStyleTestResult = await asyncAbtUserAnalysis({ posKeys: 'SmilarSyte' })
  // abtservice.getUserAbtResultForAnalysis({ abtInfos: { SmilarSyte: detailAbt['SmilarSyte'] } })

  // 搭配推荐syte
  // abtservice.getUserAbtResultForAnalysis({ abtInfos: { GetTheLook: detailAbt['GetTheLook'] } })

  // 进入页面
  var isSearchRedirect = getQueryString({ key: 'search_redir' })
  var getTraceId = function (traceid) {
    try {
      var poskeys = {
        8: 'SearchSuggestwordFeedback',
        6: 'SearchHotNew',
        3: 'SearchDefaultNew',
        4: 'SearchSuggestNew'
      }
      var abtBranch = ''
      let { result_type: paramsSearchType, result_word: paramsSearchKeyworks } = searchWordsGlobalAnalysis.get()
      var poskey = poskeys[paramsSearchType] || '' // 当前搜索页来源(搜索词类型)poskey

      if (paramsSearchType && paramsSearchKeyworks) {
        traceid = 'search`' + paramsSearchType + '`' + paramsSearchKeyworks
      }
      if (poskey) {
        let abtInfo = detailAbt[poskey]
        abtBranch = abtInfo[poskey].id
        if (abtBranch) {
          traceid += '`' + abtBranch
        }
      }
    } catch (e) {
      console.error(e)
    }
    return traceid
  }
  if (isSearchRedirect) {
    var redirectParams = ''
    redirectParams = getTraceId(redirectParams)
    if (redirectParams) {
      gbExposeTraceid('setProduct', {
        goods_id: SaPageInfo.page_param.goods_id || '',
        params: redirectParams
      })
    }
  }
  // refresh recommend
  gbExposeTraceid('refreshComponent', {
    componentName: 'recommend'
  })
  var traceid = gbExposeTraceid('getProduct', {
    goods_id
  })
  if (traceid) {
    SaPageInfo.page_param.traceid = traceid
  }

  sa('set', 'setPageData', SaPageInfo)
  sa('send', {
    activity_name: 'expose_page_loadtime',
    activity_param: {
      time: Date.now() - performance.timing.navigationStart,
      abtInfo: `-`
    }
  })
}

const GBDetailAnalysisEvent = {
  init () {
    // 曝光状态重置
    // $('.j-ccc-banner-ga-ctn').attr('data-viewed', 0)

    this.scroll_expose_event()
  },
  delivery_slide () {
    if (typeof sa !== 'undefined') {
      sa('send', { activity_name: 'click_goods_detail_freeshipping' })
    }
  },
  open_shoplook (img) {
    if (typeof sa !== 'undefined') {
      sa('send', {
        activity_name: 'click_style_gallery_image',
        activity_param: { imag_url: img }
      })
    }
  },
  scroll_expose_event () {
    const window_height = $(window).height()

    let expose_map = [
      {
        el: '.j-sa-detail-image-map',
        sa_name: 'expose_goods_detail_pic',
        // ga_obj: ['商品详情页', 'ShowDetailMap', $('.j-sa-detail-image-map').attr('data-sku')]
      },
      {
        el: '.j-sa-detail-promotion',
        sa_name: 'expose_goods_detail_promotion'
      },
      {
        el: '.j-sa-after-pay',
        sa_name: 'expose_afterpay'
      },
      // {
      //   el: '.j-sa-select-size',
      //   sa_name: 'expose_goods_detail_select_otherattr'
      // },
      {
        el: '.j-select-to-buy',
        sa_name: 'expose_goods_detail_select_to_buy'
      },
      {
        el: '.j-sa-check-size',
        sa_name: 'expose_goods_detail_check_size'
      },
      {
        el: '.j-sa-delivery',
        sa_name: 'expose_goods_detail_shipping'
      },
      {
        el: '.j-go-return-policy',
        sa_name: 'expose_goods_detail_return_policy'
      },
      {
        el: '.j-sa-cod',
        sa_name: 'expose_cod_policy'
      },
      {
        el: '.j-sa-view-review',
        sa_name: 'expose_goods_detail_reviews',
        parentContainer: 'j-direct-box'
      },
      {
        el: '.j-sa-view-category',
        sa_name: 'expose_style_gallery_image',
        ga_obj: ['商品详情页', 'ShowStyleGallery'],
        parentContainer: 'j-direct-box'
      },
      {
        el: '.j-go-size-guide',
        sa_name: 'expose_goods_detail_sizechart',
        parentContainer: 'j-direct-box'
      },
      {
        el: '.desc-title-description',
        sa_name: 'expose_goods_detail_description',
        parentContainer: 'j-direct-box'
      },
      {
        el: '.j-sa-brand-detail',
        sa_name: 'expose_aboutbrand',
        ga_obj: ['商品详情页', 'ViewAboutBrand'],
        parentContainer: 'j-direct-box'
      },
      {
        el: '.j-sa-brand-view-products',
        sa_name: 'expose_brand_products',
        parentContainer: 'j-direct-box'
      },
      {
        el: '.j-sa-brand-store-view-products',
        sa_name: 'expose_brand_products',
        is_store: true,
        parentContainer: 'j-direct-box'
      },
      {
        el: '.j-relate-goods-ga-ctn',
        sa_name: 'expose_other_options_block',
        // ga_obj: ['推荐列表', 'ViewItems', '商品详情页-推荐列表-OtherOptions'],
        parentContainer: 'j-direct-box',
        callback: function () {
          const wraper = $('.j-relate-goods-ga-ctn')
          const items = wraper.find('.j-relate-goods')
          GBDetailAnalysisEvent.exposeOtherOptions(wraper.length && wraper[0], items)
        }
      },
      {
        el: '.j-gtl-goods-ctn',
        sa_name: 'expose_get_the_look_new',
        parentContainer: 'j-direct-box'
      },
      {
        el: '.j-desc-title-ingredients',
        sa_name: 'expose_ingredients',
      },
      // {
      //   el: '.j-goodsd-color-drop',
      //   sa_name: 'expose_select_color_beauty',
      //   ga_obj: ['商品详情页', 'ViewSelectColorBeauty'],
      //   parentContainer: 'mgds-goodsd-info-container'
      // },
      {
        el: '.j-qty-com',
        sa_name: 'expose_qty'
      },
      {
        el: '.j-price-from',
        sa_name: 'expose_pricefrom'
      },
      {
        el: '.j-ccc-banner-ga-ctn',
        callback: function () {
          appEventCenter && appEventCenter.$emit('exposeCccBanner')
        }
      },
      {
        el: '.j-details-pic',
        sa_name: 'expose_details_picture'
      },
      {
        el: '.j-sa-details-material',
        sa_name: 'expose_material',
        parentContainer: 'j-direct-box'
      },
      {
        el: '.j-sa-detailfpos',
        sa_name: 'expose_moredetails',
        parentContainer: 'j-direct-box'
      }
    ]

    // 细节图曝光埋点
    const getElInfo = function (item) {
      const el = item.el
      const imgBox = $(el)
      let offsetTop = null
      let getBoundingClientRect = null
      if (imgBox.length == 0 || (imgBox.length != 0 && imgBox.children().length == 0)) return { offsetTop, getBoundingClientRect }
      getBoundingClientRect = imgBox[0].getBoundingClientRect()
      offsetTop = imgBox.offset().top
      return {
        offsetTop,
        getBoundingClientRect
      }
    }

    if (scroll_timeout) {
      clearTimeout(scroll_timeout)
    }
    $(window).off('scroll.detail_scroll_expose')
    $(window).on('scroll.detail_scroll_expose', function () {
      // console.log(event)
      if (SaPageInfo.page_name != 'page_goods_detail') return

      if (scroll_timeout) {
        clearTimeout(scroll_timeout)
      }
      scroll_timeout = setTimeout(() => {
        expose_map.map(function (item) {
          let info = getElInfo(item)
          item.offset_top = info.offsetTop || null
          item.getBoundingClientRect = info.getBoundingClientRect || null
        })

        let parentContent = {}
        expose_map = expose_map.filter((item) => {
          if (item.getBoundingClientRect && item.parentContainer && !parentContent[item.parentContainer]) {
            let parentDom = document.getElementsByClassName(item.parentContainer)[0]
            let rect = parentDom && parentDom.getBoundingClientRect()
            parentContent[item.parentContainer] = {
              bottom: rect.bottom
            }
            // 减去 view more 遮挡的高度
            let viewMoreDom = $('.product-middle__more')
            if (viewMoreDom && viewMoreDom.length > 0) {
              parentContent[item.parentContainer].bottom = parentContent[item.parentContainer].bottom - viewMoreDom.height()
            }
          }
          let inScreen = item.parentContainer && item.getBoundingClientRect && parentContent[item.parentContainer]
            ? parentContent[item.parentContainer].bottom > 0 && parentContent[item.parentContainer].bottom > item.getBoundingClientRect.top && item.offset_top - ($(window).scrollTop() + window_height) < 0
            : item.offset_top - ($(window).scrollTop() + window_height) < 0

          if (item.offset_top !== null
            && inScreen) {
            if (item.sa_name) {
              let param = {}
              if(item.sa_name === 'expose_goods_detail_promotion') {
                let target = $('.promotion-enter__wrap')?.[0] || null
                param.promotion_type_id = target.getAttribute('promotion-type-id') || '',
                param.promotion_logo_type = target.getAttribute('promotion-logo-type') || '',
                param.promotion_id = target.getAttribute('promotion-id') || '',
                param.is_brand = target.getAttribute('is-brand') ? 1 : 0,
                param.brand_code = target.getAttribute('brand-code') || ''
                const ruleType = target.getAttribute('rule-type')
                if(ruleType) {
                  let type = ruleType == 'no_discount' ? 'nosale' : 'sale',
                      single = Number(target.getAttribute('single-num')) ? '_item' : '',
                      order = Number(target.getAttribute('order-num')) && ruleType != 'no_discount' ? '_promotion' : Number(target.getAttribute('order-num')) && ruleType == 'no_discount' ? '_collection' : ''
                  param.type = type + single + order
                }
              }
              if (item.sa_name === 'expose_moredetails') {
                param = document.querySelector('.j-sa-detailfpos').dataset
              }
              // if (item.sa_name === 'expose_goods_detail_select_otherattr') {
              //   param = document.querySelector('.j-sa-select-size').dataset
              // }
              if (item.sa_name === 'expose_brand_products') {
                const {
                  brand_code,
                  brand_type,
                  name,
                  designer_id,
                  sc_id,
                  // abt_ana_info,
                  brand_info,
                  brand_label,
                  button_label,
                  flash_sale_stype,
                  is_alone_store_brand
                } = document.querySelector(`.j-sa-brand${item?.is_store ? '-store' : ''}-view-products`).dataset

                param = {
                  // abtest: abt_ana_info,
                  brand_info,
                  brand_label,
                  button_label,
                  brand_code,
                  brand_type,
                  src_module: 'DetailBrand',
                  src_identifier: [`on=${brand_type}`, `cn=${name}`, `hz=0`, `ps=1_1`, `jc=${designer_id ? `sheinxDesignerInformation_${designer_id}` : (brand_type === 'store' ? `thirdPartyStoreHome_${brand_code}` : `itemPicking_${sc_id}`)}`].join('`')
                }
                if(!is_alone_store_brand){
                  param.flash_sale_stype = flash_sale_stype
                }

                if (brand_type === 'store') {
                  daEventCenter.triggerNotice({
                    daId: '1-6-4-44',
                    extraData: {
                      brand_code,
                      page_nm: 'page_goods_detail'
                    }
                  })
                }
              }
              if(item.sa_name === 'expose_goods_detail_description') {
                let domDataSet = document.querySelector('.j-ga-description').dataset
                let { sellPoint, from, is_trend, trend_word_id, goods_id } = domDataSet
                param = Object.assign(param, {
                  sellPoint: Number(sellPoint),
                  from: Number(from),
                  ...(trend_word_id ? { trend_word_id, is_trend, goods_id } : {}),
                })
              }
              sa('send', {
                activity_name: item.sa_name,
                activity_param: param
              })
            }
            item.callback && typeof item.callback == 'function' && item.callback()
            return false
          } else {
            return true
          }
        })

        if (!expose_map.length) {
          $(window).off('scroll.detail_scroll_expose')
        }
      }, 300)
    })
  },
  exposeFloorTab(payload) {
    sa('send', {
      activity_name: 'expose_goods_detail_tab',
      activity_param: {
        tab_list: payload.map((key, index) => `${index + 1}\`${key}\`${index + 1}`).join(',')
      }
    })
  },
  clickFloorTab ({ key, index }) {
    sa('send', {
      activity_name: 'click_goods_detail_tab',
      activity_param: {
        tab_list: `${index}\`${key}\`${index}`
      }
    })
  },
  // 点击 RecentlyViewed 模块 item
  clickRecentlyViewedItem ({ params }) {
    sa('send', {
      activity_name: 'click_recently_viewed',
      activity_param: {
        goods_list: `${params.goodsId}\`${params.skuId}\`${params.spuId}\`${params.goodsIndex}\`${params.page}\`${params.operation}\`${params.recMark}\`\`pri_${params.price}|pri_${params.originPrice}\`mall_${params.mall}`, // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
        traceid: params.traceId,
        // abtest: params.abtest
      }
    })
  },
  // 曝光上报 RecentlyViewed 模块 item
  exposeRecentlyViewedItem () {
    const expose = new goodsExpose()

    expose.initInstance({
      config: {
        scrollContainer: '.recently-viewed__content',
        scrollTopMask: $('.recently-viewed__header').height() || 0,
        targetExposeRadio: 0.1,
        section: [
          {
            code: 'recently-viewed__item',
            container: 'recently-viewed__container',
            content: 'recently-viewed__item',
            target: 'recently-viewed__item-cover'
          }
        ]
      },
      getExposeData: ({ list }) => {
        Object.keys(list).forEach((type) => {
          list[type].forEach(({ content }) => {
            const goodsId = content.getAttribute('data-goods-id')
            const skuId = content.getAttribute('data-sku-id')
            const spuId = content.getAttribute('data-spu-id')
            const index = content.getAttribute('data-goods-index')
            const page = 1
            const operation = 1
            const recMark = content.getAttribute('data-recmark') || ''
            const traceId = content.getAttribute('data-trace-id') || ''
            const price = content.getAttribute('data-us-price') || ''
            const originPrice = content.getAttribute('data-us-origin-price') || ''
            const mall = content.getAttribute('data-mall') || ''
            // const abtest = ''

            sa('send', {
              activity_name: 'expose_recently_viewed',
              activity_param: {
                goods_list: `${goodsId}\`${skuId}\`${spuId}\`${index}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|pri_${originPrice}\`mall_${mall}`, // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识`pri_在售价格｜pri_原价 （美元，保留小数点两位）`其它标识
                traceid: traceId,
                // abtest
              }
            })
          })
        })
      }
    })
    return expose
  },
  exposeDetailReviews() {
    const expose = new goodsExpose()
    expose.initInstance({
      config: {
        targetExposeRadio: 0.3,
        section: [
          {
            code: 'reviews-item',
            container: 'reviews__list',
            content: 'reviews__item',
            target: 'reviews__item-con'
          }
        ]
      },
      getExposeData: ({ list }) => {
        Object.keys(list).forEach((type) => {
          let reviewTargets = []
          let reportTargets = []
          list[type].forEach(({ target }) => {
            let commentId = target.getAttribute('data-comment-id')
            if (commentId.indexOf('rp') > -1) {
              reportTargets.push(commentId.replace('rp', ''))
            } else {
              reviewTargets.push(target)
            }
          })
          // if (reviewTargets.length > 0) {
          //   daEventCenter.triggerNotice({
          //     daId: '1-6-7-5',
          //     target: reviewTargets,
          //     extraData: {
          //       location: reviewTargets[0].getAttribute('data-review-location'),
          //       rank: reviewTargets[0].getAttribute('data-review-rank')
          //     }
          //   })
          // }
          reportTargets.forEach(id => {
            daEventCenter.triggerNotice({
              daId: '1-6-7-22',
              extraData: {
                freetrial_id: id
              }
            })
          })
        })
      }
    })
    return expose
  },
  exposeStyleGallery() {
    const expose = new goodsExpose()
    expose.initInstance({
      config: {
        targetExposeRadio: 0.3,
        section: [
          {
            code: 'style-gallery',
            container: 'style-gallery-container',
            content: 'style-gallery-con',
            target: 'style-gallery-target'
          }
        ]
      },
      getExposeData: ({ list }) => {
        Object.keys(list).forEach((type) => {
          daEventCenter.triggerNotice({
            daId: '1-6-3-3',
            extraData: {
              num: list[type][0].target.getAttribute('data-num')
            }
          })
        })
      }
    })
    return expose
  },
  exposeDetailOPos() {
    const expose = new goodsExpose()
    expose.initInstance({
      config: {
        targetExposeRadio: 0.3,
        section: [
          {
            code: 'detailops',
            container: 'detailops-container',
            content: 'detailops-content',
            target: 'detailops-target'
          }
        ]
      },
      getExposeData: ({ list }) => {
        Object.keys(list).forEach(type => {
          list[type].forEach(({ target }) => {
            daEventCenter.triggerNotice({
              daId: '1-6-4-37',
              target
            })
          })
        })
      }
    })
    return expose
  },
  exposeItemReport() {
    daEventCenter.triggerNotice({
      daId: '1-6-4-39'
    })
  },
  exposeUsInfo() {
    const expose = new goodsExpose()
    expose.initInstance({
      config: {
        section: [
          {
            code: 'footer-sheininfo',
            container: 'footer-sheininfo',
            content: 'footer-sheininfo__link',
            target: 'footer-sheininfo__link-con',
          }
        ]
      },
      getExposeData: ({ list }) => {
        if (!!list['footer-sheininfo'] || list['footer-sheininfo'].length > 0) {
          const keys = []
          list['footer-sheininfo'].forEach(({ content }) => {
            keys.push(content.getAttribute('data-seat'))
          })
          daEventCenter.triggerNotice({
            daId: '1-6-5-1',
            extraData: {
              content_list: keys.join(','),
              privacy: 1
            }
          })
        }
      }
    })
  },
  // 站外 商品详情 view more点击
  clickGoodsDetailViewMore () {
    sa('send', {
      activity_name: 'click_goods_detail_view_more'
    })
  },
  // 站外 similar items,best seller view more点击
  clickViewAll ({ type = '' } = {}) {
    if (!type) return

    const activityName = {
      similar: 'click_similar_items_view_all',
      bestSeller: 'click_best_seller_view_all'
    }

    sa('send', {
      activity_name: activityName[type] || ''
    })
  },
  // 站外 similar items view more点击
  clickBestSellerCat ({ id = '', index = 0 }) {
    sa('send', {
      activity_name: 'click_best_sellers_label',
      activity_param: {
        label_id: `${index}\`${id}`
      }
    })
  },
  // related search begin
  isExposeRelatedSearch: false,
  relatedSearchList: [],
  relatedExposeInstance: null,
  relatedSearchExpose () {
    this.relatedExposeInstance = new goodsExpose()
    this.relatedExposeInstance.initInstance({
      config: {
        section: [
          {
            code: 'related-search',
            container: 'related-search-container',
            content: 'related-search-content',
            target: 'related-search-a'
          }
        ]
      },
      getExposeData: ({ list }) => {
        if (!!list['related-search'] || list['related-search'].length > 0) {
          list['related-search'].forEach(({ target }) => {
            this.relatedSearchAddView({ target })
          })
        }
        this.relatedSearchView()
      }
    })
  },
  relatedAdjustContainerInfo () {
    if (!this.relatedExposeInstance) return
    this.relatedExposeInstance.adjustContainerInfo()
  },
  relatedSearchAddView ({ target = {} } = {}) {
    const id = target.getAttribute('data-id')
    const type = target.getAttribute('data-type')
    const name = target.getAttribute('data-name')
    const index = target.getAttribute('data-index')
    this.relatedSearchList.push(`${type}\`${id}\`${name}\`${Number(index) + 1}`)
  },
  relatedSearchView () {
    if (this.relatedSearchList.length <= 0) return
    if (!this.isExposeRelatedSearch) {
      this.isExposeRelatedSearch = true
    }
    sa('send', {
      activity_name: 'expose_related_search',
      activity_param: {
        related_list: this.relatedSearchList.join(',')
      }
    })

    this.relatedSearchList = []
  },
  relatedSearchClick ({ id, type, name, index } = {}) {

    sa('send', {
      activity_name: 'click_related_search',
      activity_param: {
        related_list: `${type}\`${id}\`${name}\`${Number(index) + 1}`
      }
    })
  },
  destroyRelatedExposeInstance () {
    this.isExposeRelatedSearch = false
    this.relatedSearchList = []
    this.relatedExposeInstance?.destroyInstance()
    this.relatedExposeInstance = null
  },
  /**
     * 点击 get the look 进入到二级页面
     */
  openSecondPage () {
    sa('send', {
      activity_name: 'click_get_the_look_suit'
    })
  },
  /**
     * 点击 the options
     */
  clickOptionsItems () {
    sa('send', {
      activity_name: 'click_other_options_block'
    })
  },
  commendSendSa (name) {
    sa('send', {
      activity_name: name,
      activity_param: {}
    })
  },
  exposeOtherOptions (wrapper, items) {
    const itemHalfWidth = items[0].offsetWidth / 2
    const exposeAreaWidth = wrapper.offsetWidth - itemHalfWidth // 露出一半则曝光
    const expDoms = []
    items.forEach((item) => {
      const target = item?.$el || item
      const itemLeft = target?.getBoundingClientRect?.().left
      const viewed = target.getAttribute('viewed')
      if(viewed) return
      if (itemLeft <= exposeAreaWidth && !target.isExpose) {
        target.setAttribute('viewed', 1)
        expDoms.push(target)
      }
    })

    expDoms.length && daEventCenter.triggerNotice({
      daId: '2-3-2',
      target: expDoms
    })
  }
}

export {
  refreshAnalyisParams,
  setAnalyisParams
}

export default GBDetailAnalysisEvent
