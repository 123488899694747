<template>
  <div
    v-if="compShowConfig.showFoldEntry"
    v-expose="{
      id:'1-6-1-223',
    }"
    v-tap="{
      id:'1-6-1-224',
    }"
    class="attrs-fold__content"
    @click="openQuick"
  >
    <div class="attrs-fold__content-box">
      <div class="attrs-fold__content-title">
        {{ language.SHEIN_KEY_PWA_31281 || 'Options:' }}
      </div>
      <div class="attrs-fold__content-listBox">
        <div class="attrs-fold__content-list">
          <span
            v-for="(item, index) in saleAttrsArr"
            :key="index"
          >
            <span
              class="attrs-fold__content-item"
              :class="{
                active: isActive(item),
              }"
            > {{ saleStr(item) }}</span>
            <span v-if="index !== saleAttrsArr.length - 1">, </span> 
          </span>
        </div>
        <div
          v-if="sizeStockTips"
          class="attrs-fold__content-soldout entry-soldout"
        >
          <Icon
            name="sui_icon_hourglass_12px_2"
            size="12px"
          />
          {{ soldOutTips }}
        </div>
      </div>
    </div>
    <SkcRadios 
      v-if="colorsFoldInfo.showColorList" 
      :colorsFoldInfo="colorsFoldInfo"
    />
    <NewTip
      :showHint="showHint"
      :language="language"
      :closeHint="closeHint"
    />
  </div>
  <div 
    v-else-if="compShowConfig.stockTipLocation === 'line' && sizeStockTips"
    class="attrs-fold__content-soldout"
  >
    <Icon
      name="sui_icon_hourglass_12px_2"
      size="12px"
    />
    {{ soldOutTips }}
  </div>
</template>

<script setup>
import NewTip from './NewTip.vue'
import SkcRadios from './SkcRadios.vue'

const props = defineProps({
  compShowConfig: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  saleAttrsArr: {
    type: Array,
    default: () => [],
  },
  sizeStockTips: {
    type: Boolean,
    default: false,
  },
  soldOutTips: {
    type: String,
    default: '',
  },
  colorsFoldInfo: {
    type: Object,
    default: () => ({}),
  },
  sizeInfoIndex: {
    type: Object,
    default: () => ({}),
  },
  calcSize: {
    type: Function,
    default: () => {},
  },
  showHint: {
    type: Boolean,
    default: false,
  },
  closeHint: {
    type: Function,
    default: () => {},
  },
  openQuick: {
    type: Function,
    default: () => {},
  },
})

const isActive = (item) => {
  const { isMainAttr, attr_id } = item || {}
  return !!(isMainAttr || props.sizeInfoIndex?.[attr_id]?.attr_value_name)
}

const saleStr = (item) => {
  const { isMainAttr, attr_value, attr_name, attr_id } = item || {}
  const activeSkuStr = props.calcSize(props.sizeInfoIndex?.[attr_id])
  return isMainAttr ? (attr_value || '') : (activeSkuStr || attr_name || '')
}
</script>

<style lang="less" scoped>
.attrs-fold {
    &__content {
        display: flex;
        align-items: center;
        padding: 0.11rem 0;
        min-height: 0.85rem;
    }

    &__content-box {
      flex: 1;
      display: flex;
      line-height: normal;
    }

    &__content-title {
        font-size: 14px;
        font-weight: 400;
        color: var(---sui_color_brand, #000);
        margin-right: 0.21rem;
        white-space: nowrap;
    }

    &__content-listBox {
      flex: 1;
    }

    &__content-list {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__content-soldout {
      color: var(---sui_color_highlight, #C44A01);
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      line-height: normal;

      & > span {
        margin-right: 2px;
      }
    }

    .entry-soldout {
      margin-top: 2px;
    }

    &__content-item {
        display: inline;
        font-size: 0.37333rem;
        font-style: normal;
        font-weight: 400;
        color: var(---sui_color_gray_light1, #959595);
        // margin-right: 1ch;

        &.active {
            color: var(---sui_color_black, #000);
            font-weight: bold;
        }
    }

    &__content-item > span {
        font-weight: 400;
    }

    &__content-more {
        margin-left: 0.05rem;
    }
}
</style>

