<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
import EstimatedTag from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/EstimatedTag/index.vue'
import EstimatedCountdown from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/EstimatedTag/EstimatedCountdown.vue'
import { getIsEstimatedTagPercentWrap } from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/EstimatedTag/util.js'
import salePriceWordSize from 'public/src/pages/goods_detail/utils/salePriceWordSize'
const { GB_cssRight } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default {
  name: 'NewEstimatedPrice',
  props: {
    priceCommon: {
      type: Object,
      default: () => ({}),
    },
    priceFlashInfo: {
      type: Object,
      default: () => ({}),
    },
    showFrom: {
      type: Boolean,
      default: false,
    },
    estimatedInfo: {
      type: Object,
      default: () => ({}),
    },
    iconListData: {
      type: Object,
      default: () => ({}),
    },
    goodsId: {
      type: String,
      default: '',
    },
    isEur: {
      type: Boolean,
      default: false,
    },
    showInclusiveTips: {
      type: Boolean,
      default: false,
    },
    getEstimatedInfo: {
      type: Object,
      default: () => ({}),
    },
    discountInfo: {
      type: Object,
      default: () => ({}),
    },
    promotionInfo: {
      type: Array,
      default: () => ([]),
    },
    isBeltType: {
      type: Boolean,
      default: false
    },
    isBeltTypeBrand: {
      type: Boolean,
      default: false
    },
    isFloorType: {
      type: Boolean,
      default: false
    },
    fsAbt: {
      type: Object,
      default: () => ({}),
    },
    taxIncludedConfig: {
      type: Object,
      default: (() => {})
    },
  },
  data() {
    return {
      isPercentTypeStyleWrap: false,
      isDiscountWrap: false,
      
      mainPriceElWidth: 0,
      estimatedTagElWidth: 0,
      discountElWidth: 0,
      // fromElOpcity: 0,

      GB_cssRight
    }
  },
  computed: {
    // 到手价点击热区
    // hotspotStyle () {
    //   if (this.GB_cssRight) return
    //   let width = ''
    //   let height = ''
    //   let top = ''
    //   let left = ''
    //   let { isPercentTypeStyleWrap, isDiscountWrap } = this
    //   // 划线价到手价都不换行
    //   if (!isPercentTypeStyleWrap && !isDiscountWrap) {
    //     width = this.estimatedTagElWidth + this.discountElWidth + 5 + 8 + 'px'
    //     height = '.5333rem'
    //     top = 0
    //     left = this.mainPriceElWidth + (this.isEur ? 14 : 0) + 'px'
    //   }
    //   if (!isPercentTypeStyleWrap && isDiscountWrap) {
    //     width = this.mainPriceElWidth + this.estimatedTagElWidth + 5 + 'px'
    //     height = '1.0133rem'
    //     top = '0'
    //     left = '0'
    //   }
    //   if (isPercentTypeStyleWrap && isDiscountWrap) {
    //     width = this.estimatedTagElWidth + this.discountElWidth + 5 + 8 + 'px'
    //     height = '.56rem'
    //     top = '.5067rem'
    //     left = '0'
    //   }

    //   return {
    //     width,
    //     height,
    //     top,
    //     left
    //   }
    // },
  },
  watch: {
    getEstimatedInfo(){
      this.$nextTick(() => {
        this.calcuStyles()
      })
    },
  },
  mounted () {
    this.calcuStyles()
  },
  methods: {
    calcuStyles() {
      let { mainPriceRef, estimatedTagRef, discountRef } = this.$refs
      this.isPercentTypeStyleWrap = getIsEstimatedTagPercentWrap(mainPriceRef, estimatedTagRef)
      this.isDiscountWrap = getIsEstimatedTagPercentWrap(mainPriceRef, discountRef)
      
      this.mainPriceElWidth = mainPriceRef?.getBoundingClientRect?.().width || 0
      this.estimatedTagElWidth = estimatedTagRef?.getBoundingClientRect?.().width || 0
      this.discountElWidth = discountRef?.getBoundingClientRect?.().width || 0
    }
  },
  render() {
    let {
      priceCommon,
      priceFlashInfo,
      showFrom,
      estimatedInfo,
      iconListData,
      isEur,
      showInclusiveTips,
      goodsId,
      getEstimatedInfo,
      promotionInfo,
      discountInfo,
      isPercentTypeStyleWrap,
      isDiscountWrap,
      isBeltType,
      isBeltTypeBrand,
      fsAbt,
      taxIncludedConfig,
      isFloorType
    } = this || {}
    const { language, IS_JP_LANGUAGE } = priceCommon || {}
    const { isSpecialFlash, flashType } = priceFlashInfo || {}
    const specialFlashText = flashType === 2 ? language.SHEIN_KEY_PWA_21931 : language.SHEIN_KEY_PWA_21932
    const { estimatedCompProps } = estimatedInfo || {}
    let estimatedAmountSymbolPrice = estimatedCompProps?.price?.amountWithSymbol || ''

    let mainPriceStyleColor = ''
    if(isBeltTypeBrand) mainPriceStyleColor = '#FFF'
    if(getEstimatedInfo.isPaidUser) mainPriceStyleColor = '#873C00'
    const mainPriceStyle = {
      color: mainPriceStyleColor,
      'margin-right': GB_cssRight ? '' : (!!isEur ? '3px' : '5px'),
      'margin-left': GB_cssRight ? '5px' : '',
      'margin-bottom': 
        isFloorType ? '' :
          isDiscountWrap ?
            (isPercentTypeStyleWrap ? '6px' : '2px') :
            (isPercentTypeStyleWrap ? '6px' : ''),
    }

    const fromELStyle = {
      color: mainPriceStyle.color,
      'margin-bottom': mainPriceStyle['margin-bottom'],
      // opacity: this.fromElOpcity
    }

    const fromEl = showFrom ? (
      <span
        aria-hidden="true"
        class="j-price-from price-new-estimated__from"
        style={fromELStyle}
      >
        {language.SHEIN_KEY_PWA_15320}
      </span>
    ) : null

    const moreIconEl = iconListData.showMoreDiscount ? (
      <div class="j-promotion-icon-ctn">
        {iconListData.iconList?.map((item, index) => <i key={index} class={[iconListData.iconClass]}></i>)}
      </div>
    ) : null

    let mainPrice = salePriceWordSize({ priceStr: estimatedAmountSymbolPrice, gate: fsAbt?.sheinappwordsize?.p?.sheinappwordsize === 'newprice' })

    const mainPriceEl = (<div
      ref="mainPriceRef"
      class='price-new-estimated__main'
      style={mainPriceStyle}
      {...{ directives: [{ name: 'ada', value: { level: 1, pos: 0 } }] }}
      role="text"
      domPropsInnerHTML={ mainPrice }
    ></div>)

    const { show, type, content } = taxIncludedConfig
    let showPopoverEl = (show && type == 'popover')

    let inclusiveStyle = {}
    if (isBeltTypeBrand) {
      inclusiveStyle = {
        color: '#FFF'
      }
    }
  
    const eurPopoverEl = showPopoverEl ? <s-popover
      value={showInclusiveTips}
      show-close-icon={true}
      append-to-body={false}
      content={content}
      trigger-type="user"
      placemen="top"
    >
      <i
        slot="reference"
        class="suiiconfont sui_icon_info_12px_2 price-new-estimated__inclusive-tip"
        style={inclusiveStyle}
        vOn:click_stop={() => this.$emit('inclusiveClick')}
      ></i>
    </s-popover> : null

    const estimatedEl = <div
      ref="estimatedTagRef"
      class="price-new-estimated__estimated-tag"
    >
      <EstimatedTag
        ref="estimatedTagCompRef"
        analysis-data={{
          location: 'page',
        }}
        language={language}
        price-update={+goodsId}
        estimated-info={getEstimatedInfo}
        promotion-info={promotionInfo}
        is-percent-type-style-wrap={isPercentTypeStyleWrap}
        is-belt-type={isBeltType}
        is-belt-type-brand={isBeltTypeBrand}
        is-floor-type={isFloorType}
        is-quick-add
        hasLink={true}
        scene="prices"
        vOn:clickLink={() => this.$emit('clickLink')}
      />
    </div>

    let discountElStyle = {
      color: isBeltTypeBrand ? 'rgba(255, 255, 255, 0.8)' : '#666'
    }
    const discountEl = <div
      {...{ directives: [{ name: 'ada', value: { level: 1, pos: 0 } }] }}
      ref="discountRef"
      aria-label={discountInfo.retailPrice.value}
      class="price-new-estimated__sale"
      style={discountElStyle}
      role="text"
      vOn:click={() => this.$emit('clickLink')}
    >
      <span
        class="price-new-estimated__sale-price"
      >
        {discountInfo.retailPrice.value}
      </span>
      <i
        class="suiiconfont sui_icon_more_right_12px_2 price-new-estimated__sale-icon"
      ></i>
    </div>

    // const estiamtedHotSpotEl = (
    //   <div
    //     class="price-new-estimated__hotspot"
    //     style={this.hotspotStyle}
    //     onClick={() => this.$refs.estimatedTagCompRef.handleClick()}
    //   >
    //   </div>
    // )

    const new1Els = !IS_JP_LANGUAGE ? 
      [ moreIconEl, fromEl, mainPriceEl, eurPopoverEl ] : 
      [ moreIconEl, mainPriceEl, fromEl, eurPopoverEl ]

    const specialFlashExposeData = {
      id: flashType == 2 ? '1-6-1-67' : '1-6-1-68',
      data: {
        exclusvieshowtype: 2
      }
    }

    return (
      <div
        class={['price-new-estimated', { 'price-new-estimated__special-flash': isSpecialFlash && !isBeltType && !isFloorType }]}
      >
        {isSpecialFlash && specialFlashText && !isBeltType && !isFloorType ?
          <div 
            class="price-new-estimated__special-flash-content"
            {...{ directives: [{ name: 'expose', value: specialFlashExposeData }] }}
          >{ specialFlashText }</div> : null
        }
        <div class="price-new-estimated__basic">
          <div class="price-new-estimated__left">
            {new1Els}
            {estimatedEl}
            {discountEl}
            {/* {estiamtedHotSpotEl} */}
          </div>
          <div class="price-new-estimated__right">
            {estimatedCompProps.config.isShowCountdown && !isFloorType ?
              <EstimatedCountdown
                estimated-price-end-time={estimatedCompProps.config.estimatedPriceEndTime}
              /> : null
            }
          </div>
        </div>
      </div>
    )
  },
}
</script>

<style lang="less">
.price-new-estimated {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-top: .0533rem;
  &__special-flash-content {
    color: #fa6338;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  &__special-flash {
    flex-direction: column;
    background: linear-gradient(270deg, #ffffff 17.52%, rgba(255, 255, 255, 0) 64.13%),
      linear-gradient(180deg, #ffeae5 0%, rgba(255, 246, 243, 0) 100%);
    padding-top: 0.2133rem;
    padding-left: 0.32rem;
    margin-left: -0.32rem;
    margin-top: -0.2133rem;
  }

  &__basic {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
  }
  &__sale {
    display: flex;
    align-items: center;
  }
  &__sale-price {
    .font-dpr(20px);
    text-decoration: line-through;
  }
  &__sale-icon {
    .font-dpr(20px) !important;
  }
  &__from {
    .flexbox();
    .align-center();
    .font-dpr(24px);
    font-weight: 400;
    direction: ltr /*rtl:ignore*/;
    color: #FA6338;
    line-height: 14px;
    margin-right: .0533rem;
    align-self: flex-end;
    margin-bottom: 2px;
  }
  &__main {
    .flexbox();
    .align-center();
    .font-dpr(40px);
    font-weight: bold;
    direction: ltr /*rtl:ignore*/;
    color: #FA6338;
    line-height: 20px;
  }
  
  &__inclusive-tip {
    display: inline-block;
    color: #959595;
    margin-left: 0.0667rem;
    margin-left: -1.5px;
    margin-right: 4px;
    z-index: 1;
    align-self: center;
  }
  &__estimated-tag {
    margin-right: 8px;
  }
  &__right {
    display: flex;
    align-items: center;
    height: 20px;
  }
  // &__hotspot {
  //   position: absolute;
  //   opacity: 0.2;
  // }

  .icon-limitedoff-new,
  .sui_icon_time_15px {
    color: #fa6338 /*rw:#FF696E*/;
    .font-dpr(36px);
    .margin-r(0.04rem);
  }
}
</style>
