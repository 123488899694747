<template>
  <div class="goods-qty__com j-qty-com">
    <div
      class="calc"
    >
      <span
        :class="['iconfont', 'icon-minus', addToBagForm.quantity <= 1 ? 'disabled' : '']"
        @click="handleReduce"
      ></span>
      <input
        type="text"
        :value="value"
        :disabled="addCartUpperLimit === 0"
        readonly
      />
      <span
        :class="['iconfont', 'icon-normal-plus', addCartUpperLimit <= value ? 'disabled' : '']"
        @click="handleAdd"
      ></span>
    </div>
    <div class="goods-qty__tips">
      <p
        v-if="buyMultipleText"
        class="goods-qty__buyMultipleText"
      >
        <Icon
          name="sui_icon_activity_orange_12px"
          size="12px"
          style="margin-right: 2px"
        />
        {{ buyMultipleText }}
      </p>
      <div
        v-if="buyMultipleText && stockRule"
        class="goods-qty__tips-gap"
      ></div>
      <div
        v-if="stockRule"
        class="goods-qty__tips-stock"
        :class="[stock === 0 ? 'grey' : 'orange', saleAttrsFoldAb ? 'fold' : '']"
      >
        <Icon
          name="sui_icon_hourglass_12px_2"
          size="12px"
          style="margin-right: 2px"
        />
        {{ stockTip }}
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
export default {
  name: 'GoodsQty',
  components: {
    Icon
  },
  data() {
    return {
      value: 1,
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['addToBagForm', 'skuInfo']),
    ...mapGetters('newProductDetail/common', ['mallCode', 'detail', 'attrSize', 'language', 'pageCommonInfo', 'sizeRule', 'saleAttrsFoldAb', 'getEstimatedInfo', 'price']),
    ...mapGetters('newProductDetail/SizeBox', ['tipsCombo', 'sizeComposeData']),
    ...mapGetters('newProductDetail/SaleAttrsFold', ['compShowConfig']),
    // 库存（从快车迁移过来的逻辑）
    stock() {
      const { detail, mallCode, skuInfo } = this
      if (!detail || mallCode === undefined) {
        return 0
      }
      const stock = detail?.is_on_sale == 0 ? 0 : Number(detail?.mall?.[mallCode]?.stock || 0)
      return skuInfo?.sku_code ? skuInfo?.mall?.[mallCode]?.stock || 0 : stock
    },
    // 闪购加车上限
    flashSaleLimit() {
      const { detail, mallCode } = this
      const flashSaleData = detail?.mall?.[mallCode]
        ?.promotionInfo?.find(i => i.typeId == 10)
      if (!flashSaleData) {
        return 100000
      }
      const currentTime = Date.now() / 1000
      const {
        singleLimitType,
        buyLimit,
        soldNum,
        endTimestamp,
        singleNum
      } = flashSaleData
      const hasLimit = (singleLimitType == 1 || singleLimitType == 3)
      return hasLimit &&
        Number(buyLimit) > Number(soldNum) &&
        Number(currentTime) < Number(endTimestamp)
        ? singleNum
        : 100000
    },
    // 一口价加车上限（从快车迁移过来的逻辑）
    underPriceLimit() {
      const { detail, mallCode } = this
      const underPriceInfo = detail?.mall?.[mallCode]
        ?.promotionInfo?.find(i => i.typeId == 30)
      const limitNum = Number(underPriceInfo?.singleNum)
      if (isNaN(limitNum) || !limitNum) {
        return 100000
      }
      return limitNum
    },
    // 加车上限（从快车迁移过来的逻辑）
    addCartUpperLimit() {
      const { stock, flashSaleLimit, underPriceLimit } = this
      return Math.min(stock, flashSaleLimit, underPriceLimit)
    },
    stockRule() {
      const { soldoutTipsLimitNum } = this.tipsCombo
      // 展示销售属性折叠入口的时候,qty不展示库存提示
      if (this.saleAttrsFoldAb && this.compShowConfig.stockTipLocation !== 'qty') return false
      return this.stock > 0 && this.stock <= soldoutTipsLimitNum
    },
    stockTip() {
      if (this.stock === 0) return this.language.SHEIN_KEY_PWA_14987
      const { saleAttrList } = this.sizeComposeData
      const isSelectSku = this.skuInfo?.sku_code
      // 只有一个sku要显示剩余数量 => 无次级销售属性的时候要显示剩余数量
      const { skuList, skcSaleAttr } = saleAttrList || {}
      const skuLength = skuList?.length || 0
      // const hasMoreSku = skuLength > 1
      const noSkcSaleAttr = skuLength > 0 && skcSaleAttr?.length != 0
      if (!isSelectSku && noSkcSaleAttr) return this.language.SHEIN_KEY_PWA_16827
      return template(this.stock, this.language.SHEIN_KEY_PWA_16814).trim()
    },
    buyMultipleText() {
      // 当数量选择器的数量小于等于n件到手价的n，展示文案为 Buy {0} save {1}， {0}取n件到手价的n，{1}取买n件时整体的减免金额；
      // 当数量选择器的数量大于n件到手价的n,展示文案为 Buy more save more
      let { language, getEstimatedInfo } = this
      let { isSatisfiedBuyMultiple, buyMultiplePic, isSatisfiedBuyMultipleFromPromotion, isSatisfiedBuyMultipleFromCoupon, optimalCoupon, mulSaleInfo } = getEstimatedInfo || {}
      if (!isSatisfiedBuyMultiple) return null
      if (this.value <= buyMultiplePic) {
        if (isSatisfiedBuyMultipleFromPromotion) {
          return template(
            buyMultiplePic,
            mulSaleInfo?.retail_discount_price?.amountWithSymbol,
            language.SHEIN_KEY_PWA_31771 || 'Buy {0} save {1}'
          )
        }
        if (isSatisfiedBuyMultipleFromCoupon) {
          return template(
            buyMultiplePic,
            optimalCoupon?.retail_coupon_discount_price?.amountWithSymbol,
            language.SHEIN_KEY_PWA_31771 || 'Buy {0} save {1}'
          )
        }
      }
      return language.SHEIN_KEY_PWA_31774 || 'Buy more save more'
    }
  },
  watch: {
    'addToBagForm.quantity': {
      immediate: true,
      handler (val) {
        this.value = val
      }
    }
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateAddToBagForm']),
    handleReduce () {
      this.clickQty()
      if (this.addToBagForm.quantity <= 1) {
        return
      }
      this.updateAddToBagForm({
        ...this.addToBagForm,
        quantity: this.addToBagForm.quantity - 1
      })
    },
    handleAdd () {
      this.clickQty()
      if (this.addToBagForm.quantity < this.addCartUpperLimit) {
        this.updateAddToBagForm({
          ...this.addToBagForm,
          quantity: this.addToBagForm.quantity + 1
        })
      }
    },
    clickQty () {
      daEventCenter.triggerNotice({
        daId: '1-6-1-16'
      })
    }
  }
}
</script>

<style lang="less">
.goods-qty__com{
  display: flex;
  align-items: center;
  .calc{
    display: flex;
    .margin-l(.32rem);
    .margin-r(.2133rem);
    span{
      width: .64rem;
      height: .64rem;
      background:rgba(246,246,246,1);
      color: #222;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      &.disabled{
        opacity: .3;
      }
    }
    input{
      width: .96rem;
      height: .64rem;
      text-align: center;
      font-weight: bold;
      border: none;
      outline: none;
      .font-dpr(28px);
      &[disabled] {
          color: #ccc;
      }
    }
  }
  .orange{
    color: @sui_color_highlight;
  }
  .grey{
    color: #ccc;
  }
  .fold {
    line-height: normal;
    display: flex;
    align-items: center;
  }
}
.goods-qty {
  &__tips {
    display: flex;
    flex-direction: column;
  }
  &__tips-gap {
    height: .1067rem;
  }
  &__tips-stock {
    display: flex;
    align-items: center;
  }
  &__buyMultipleText {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #C44A01;
    display: flex;
    align-items: center;
  }
}
</style>
